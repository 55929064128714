/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowLeft from '../ArrowLeft';


import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import ComplexTable from 'containers/Pages/Table';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';

import { ImMail4 } from 'react-icons/im';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FaTelegramPlane, FaPhoneAlt, FaWhatsapp} from 'react-icons/fa';

import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
import { MdOutlineTaskAlt, MdOutlineUnpublished, MdWarningAmber, MdOutlineTextsms, MdMailOutline, MdOutlinePhoneIphone } from 'react-icons/md';
import Modal from '@material-ui/core/Modal';
import styled from 'styled-components';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
import { FaPaperPlane } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import {getTargetInfosByTargetID} from 'services/adminDashboard/target'
import {getMessagesStatus} from 'services/adminDashboard/target'
import {postResend} from 'services/mapsPortal/alerts'


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));

const Body = styled.div`

background-color: white;

`
const Footer = styled.div`

background-color: white;

`

const TabelaResend = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo, value, closeModal, selectedUsers, clientUser} = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [tableData, setTableData] = useState(value);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [resendUsers, setResendUsers] = useState([]);
  const [usersChannels, setUsersChannels] = useState([]);
  const id = open ? 'simple-popover' : undefined
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const ref = useRef();
  const refEdit = useRef();
  const refAdd = useRef();
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [sendFilters, setSendFilters] = useState([]);
  const [resend, setResend] = useState([]);
  const [usersID, setUsersID] = useState([]);
  const [loadingResend, setLoadingResend] = useState(true);

  const handleClose = () => {
    setOpen(null);
    setReloadMod(true);
  }

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);
  
  const handleAddSendFilter = (element) => {
    let aux = []
  
    if(sendFilters.includes(element)){
      aux = sendFilters.filter((value)=>{
        return value != element
      })
      setSendFilters(aux)
    }else{
      aux = sendFilters
      aux.push(element)
      setSendFilters(aux)
    }
    setReload(true)
  }
  const executeResend = async() => {
    let aux = []
    try{
      aux.push({id_alerta: tableData.split("_")[4], 
        tipo_envio: sendFilters, 
        destinatarios: resendUsers, 
        nome_empresa: tableData.split("_")[0], 
        tipo: tableData.split("_")[20]});
      await postResend(aux);
      openToast('success', 'Retificação realizada com sucesso!');

    }catch{() => {
      openToast("error", "Problema ao realizar o reenvio!");
    }}
    
  };
  console.log(tableData);
  const loadInfosUsers = async() =>{
    let alert_type = ""
    let id_alert = ""
    if(tableData.split("_")[15]=="Boletins"||tableData.split("_")[15]=="Avisos Meteorológicos"){
      if(tableData.split("_")[15]=="Boletins"){
        alert_type = "boletim"
      }else{
        alert_type = "Avisos_Meteorológicos"
      }
      id_alert = tableData.split("_")[1];
    }else{
      alert_type = tableData.split("_")[19].replaceAll(" ", "_");
      id_alert = tableData.split("_")[4];
    }

    await getMessagesStatus(alert_type, id_alert).then((response) => {
      if(response!=null && response.length>0){
        let aux = []
        response.map((element)=>{
          aux[element.id_usuario] = {email: element.email, sms: element.sms, telefone: element.telefone, telegram: element.telegram, whatsapp: element.whatsapp, id_usuario: element.id_usuario}
        })
        setUsersChannels(aux)
      }

    })

    setReload(true)
  };

 
  const changeResendUsers = (value) =>{
    setResendUsers(value)
  }

  const loadResend = async () => {
      let aux = [];
      let dest = []; 
      let val_id = 0;
      if(tableData.split("_")[15]=="Boletins"||tableData.split("_")[15]=="Avisos Meteorológicos"){
        val_id = tableData.split("_")[16]
      }else{
        val_id = tableData.split("_")[5]
      }
      await getTargetInfosByTargetID(val_id).then((response) => {
        if(response[0] && response[0][0] && response[0][0][0] && response[0][0][0].usuarios!=null){
          var temp = response[0][0][0];
          temp.usuarios.map((value)=> {
            aux.push(value);
            dest.push(value.id_usuario);
          })
          aux[aux.length-1]['nivel'] = tableData.split("_")[6];
          aux[aux.length-1]['alvo'] = tableData.split("_")[2]!=null? tableData.split("_")[2] : "";
          aux[aux.length-1]['dur_est'] = tableData.split("_")[7]!=null? tableData.split("_")[7] : "";
          aux[aux.length-1]['data_mod'] = tableData.split("_")[8]!=null? tableData.split("_")[8] : "";

        }else{
          openToast("error", "Não existem usuários a serem carregados!");
          setLoadingResend(false);
        }
      }).catch(() => {
        openToast("error", "Problema ao carregar os dados, recarregue a página e tente novamente!");
        setLoadingResend(false);
      })

      setResend(aux);
      setUsersID(dest);
      setLoadingResend(false);
      setReload(true);
      
  };

  useEffect(() => {
    loadResend();
  }, []);

  useEffect(() => {
    if(!loadingResend && resend != null){
      loadInfosUsers();
    }
  }, [loadingResend]);
 

  const columns = ['Checkbox', 'Nome', 'Mensagem', 'TELEF.', 'EMAIL', 'WHATS.', 'SMS', 'TELEG.', 'APP', 'Nº Telefone', 'End. Email'];
  const columnsC = ['Checkbox', 'Nome', 'Nº Telefone', 'End. Email'];

  const data_vals = resend != null && usersChannels!=null && !loadingResend? resend?.map((dataP, index) => {
    return {
      index: index,
      [columns[0]]: "Checkbox",
      [columns[1]]: dataP.nome,
      [columns[2]]: { node: 
      <div className="SSS" style={{marginLeft: "12px"}}>
        <div style={{alignItems: "center"}}>
            Alerta de Tempestades com Raios - Nível {dataP.nivel? dataP.nivel : ""}<br/>
            Local: {dataP.alvo? dataP.alvo : ""}<br/>
            Horário: {dataP.data_mod? dataP.data_mod : ""}<br/>
            Duração Aproximada: {dataP.dur_est? dataP.dur_est : ""}<br/>
        </div>
      </div>, content: 'mensagem'},
      [columns[3]]: 
      usersChannels[dataP.id_usuario]?.telefone!=null ? usersChannels[dataP.id_usuario]?.telefone == true?
        { node: 
          <div className={classes.greenChipS} style={{marginLeft: "2px"}}>
            <MdOutlineTaskAlt style={{color: "#023047", width:"50px", height:"80px"}} />
          </div>, content: 'Ativo'}
      : { node: 
          <div className={classes.redChipS} style={{marginLeft: "2px"}}>
            <span>Inativo</span>
            <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
          </div>, content: 'Inativo'} : "",
      [columns[4]]: 
      usersChannels[dataP.id_usuario]?.email!=null ? usersChannels[dataP.id_usuario]?.email == true?
      { node: 
        <div className={classes.greenChipS} style={{marginLeft: "2px"}}>
          <MdOutlineTaskAlt style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Ativo'}
    : { node: 
        <div className={classes.redChipS} style={{marginLeft: "2px"}}>
          <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Inativo'}  : "",

      [columns[5]]: 
      usersChannels[dataP.id_usuario]?.whatsapp!=null ? usersChannels[dataP.id_usuario]?.whatsapp == true?
      { node: 
        <div className={classes.greenChipS} style={{marginLeft: "2px"}}>
          <MdOutlineTaskAlt style={{color: "#023047", width:"50px", height:"80px"}} />
        </div>, content: 'Ativo'}
      :
      { node: 
        <div className={classes.redChipS} style={{marginLeft: "2px"}}>
          <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Inativo'}  : "",

      [columns[6]]: 
      usersChannels[dataP.id_usuario]?.sms!=null ? usersChannels[dataP.id_usuario]?.sms == 1?
      { node: 
        <div className={classes.yellowChipS} style={{marginLeft: "2px"}}>
          <span>Enviado</span>
          <MdOutlineTaskAlt style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Enviado'}
      : 
      usersChannels[dataP.id_usuario]?.sms == 2?

      { node: 
        <div className={classes.redChipS} style={{marginLeft: "2px"}}>
          <span>Operadora</span>
          <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Falha Operadora'}  
        
        
      :
      
      usersChannels[dataP.id_usuario]?.sms == 3?

      { node: 
        <div className={classes.greenChipS} style={{marginLeft: "2px"}}>
          <span>Entregue</span>
          <MdOutlineTaskAlt style={{color: "#023047", width:"50px", height:"80px"}} />
        </div>, content: 'Entregue'} 
      
      : 
      
      usersChannels[dataP.id_usuario]?.sms == 0?

      { node: 
        <div className={classes.yellowChipS} style={{marginLeft: "2px"}}>
          <span>Na fila</span>
          <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'A enviar'} 

      :

      usersChannels[dataP.id_usuario]?.sms == -1?

      { node: 
        <div className={classes.redChipS} style={{marginLeft: "2px"}}>
          <span>Não recebe!</span>
          <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Não recebe!'} 

      : 
      
      ""

      : "",

      [columns[7]]: 
      usersChannels[dataP.id_usuario]?.telegram!=null ? usersChannels[dataP.id_usuario]?.telegram == true?
      { node: 
        <div className={classes.greenChipS} style={{marginLeft: "2px"}}>
          <MdOutlineTaskAlt style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Ativo'}
      : 
      { node: 
        <div className={classes.redChipS} style={{marginLeft: "2px"}}>
          <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Inativo'}  : "",

      [columns[8]]: 
      usersChannels[dataP.id_usuario]?.app!=null ? usersChannels[dataP.id_usuario]?.app == true?
      { node: 
        <div className={classes.greenChipS} style={{marginLeft: "2px"}}>
          <MdOutlineTaskAlt style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Ativo'}
      : 
      { node: 
        <div className={classes.redChipS} style={{marginLeft: "2px"}}>
          <MdOutlineUnpublished style={{color: "#023047", width:"50px", height:"50px"}} />
        </div>, content: 'Inativo'}  : "",

      [columns[9]]: dataP.telefone? dataP.telefone!=null? dataP.telefone : "" :"",
      [columns[10]]: dataP.email? dataP.email!=null? dataP.email : "" :"",
      id: dataP.id_usuario,
      nome: dataP.nome,
      id_usuario: dataP.id_usuario,
    } 
}) : [];

  const data_valsC = resend != null && usersChannels!=null && !loadingResend? resend?.map((dataPC, indexC) => {
    return {
      index: indexC,
      [columnsC[0]]: "Checkbox",
      [columnsC[1]]: dataPC.nome,
      [columnsC[2]]: dataPC.telefone? dataPC.telefone!=null? dataPC.telefone : "" :"",
      [columnsC[3]]: dataPC.email? dataPC.email!=null? dataPC.email : "" :"",
      id: dataPC.id_usuario,
      nome: dataPC.nome,
      id_usuario: dataPC.id_usuario,
    } 
  }) : [];
    console.log(data_valsC);
    console.log(data_vals);
    return(
      <Body>
          <Table style={{margin:"0px"}}>
            <TableBody>
              {!loadingResend && data_vals.length > 0?
              !clientUser?
              <>
                <ComplexTable 
                  columns={columns} 
                  data={data_vals}  
                  dateTimeChoice={false}
                  width={['5%', '10%', '20%', '5%', '5%', '5%', '5%', '5%', '5%', '10%','7%']}
                  type={"Reenvio"}
                  setResendUsers={changeResendUsers}
                  dataSet={data_vals}
                  noOperation={true}
                  historySelectButtons={false}
                  createButton={false}
                  buttonsWarnings={false}
                  csvButton={false}
                />
              </>
              :
                <ComplexTable 
                  columns={columnsC} 
                  data={data_valsC}  
                  dateTimeChoice={false}
                  width={['5%', '10%', '10%','7%']}
                  type={"ReenvioC"}
                  dataSet={data_valsC}
                  noOperation={true}
                  historySelectButtons={false}
                  createButton={false}
                  buttonsWarnings={false}
                  csvButton={false}
                />
              : !loadingResend  && data_vals.length == 0?
                
                <div style={{marginTop:"5%", marginBottom:"5%", marginLeft:"50%", fontSize:"20px"}}>Sem Usuários</div>

              : <div style={{marginTop:"20%"}}><CircularProgress style={{ marginLeft: "calc(48%)" }} size={50} thickness={1} color="primary" /></div>}
            </TableBody>
          </Table>
          <Footer>
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", boxShadow: "4px 8px 8px 10px DarkGrey"}}>
              <div style={{width: "100%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px", justifyContent:"space-between"}}>
                  <div style={{marginLeft:"5%", padding:"10px 20px 10px 20px"}} className={classes.outlineButton5} onClick={closeModal}>
                    <span span className={classes.outlineText1}>CANCELAR</span>
                  </div>
                  {!clientUser && !loadingResend && data_vals.length > 0?
                  <div>
                    <div style={{width: "100%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px", justifyContent:"flex-start"}}>
                      <Tooltip  title="Email">
                        <div style={{padding:"10px 20px 10px 20px", backgroundColor:sendFilters!=undefined? sendFilters.includes("Email")? "#023047" : "transparent" : "transparent"}} className={classes.buttonBottom} onClick={() => handleAddSendFilter("Email")}>
                          <i style={{display:"flex"}}><MdMailOutline style={{width: "30px", height: "30px", color:sendFilters!=undefined? sendFilters.includes("Email")? "white" : "#023047" : "#023047"}}></MdMailOutline></i>
                        </div>
                      </Tooltip>
                      <Tooltip  title="Whatsapp">
                        <div style={{padding:"10px 20px 10px 20px", borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", pointerEvents: "none", borderTopRightRadius:"0px", borderBottomRightRadius:"0px", backgroundColor:sendFilters!=undefined? sendFilters.includes("Whatsapp")? "#023047" : "transparent" : "transparent"}} className={classes.buttonBottom} onClick={() => handleAddSendFilter("Whatsapp")}>
                          <i style={{display:"flex"}}><FaWhatsapp style={{width: "30px", height: "30px", color:sendFilters!=undefined? sendFilters.includes("Whatsapp")? "white" : "#023047" : "#023047"}}></FaWhatsapp></i>
                        </div>
                      </Tooltip>
                      <Tooltip  title="SMS">
                        <div style={{padding:"10px 20px 10px 20px", borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", borderTopRightRadius:"0px", borderBottomRightRadius:"0px",  backgroundColor:sendFilters!=undefined? sendFilters.includes("SMS")? "#023047" : "transparent" : "transparent"}} className={classes.buttonBottom} onClick={() => handleAddSendFilter("SMS")}>
                          <i style={{display:"flex"}}><MdOutlineTextsms style={{width: "30px", height: "30px", color:sendFilters!=undefined? sendFilters.includes("SMS")? "white" : "023047" : "#023047"}}></MdOutlineTextsms></i>
                        </div>
                      </Tooltip>
                      <Tooltip  title="Telegram">
                        <div style={{padding:"10px 20px 10px 20px", borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px", borderTopRightRadius:"0px", borderBottomRightRadius:"0px",  backgroundColor:sendFilters!=undefined? sendFilters.includes("Telegram")? "#023047" : "transparent" : "transparent"}} className={classes.buttonBottom} onClick={() => handleAddSendFilter("Telegram")}>
                          <i style={{display:"flex"}}><FaTelegramPlane style={{width: "30px", height: "30px", color:sendFilters!=undefined? sendFilters.includes("Telegram")? "white" : "#023047" : "#023047"}}></FaTelegramPlane></i>
                        </div>
                      </Tooltip>
                      <Tooltip  title="Mobile">
                        <div style={{padding:"10px 20px 10px 20px", borderTopLeftRadius:"0px", borderBottomLeftRadius:"0px",  backgroundColor:sendFilters!=undefined? sendFilters.includes("Mobile")? "#023047" : "transparent" : "transparent", marginRight:"20px", pointerEvents: "none"}} className={classes.buttonBottom} onClick={() => handleAddSendFilter("Mobile")}>
                          <i span style={{display:"flex"}}><MdOutlinePhoneIphone style={{width: "30px", height: "30px", color:sendFilters!=undefined? sendFilters.includes("Mobile")? "white" : "#023047" : "#023047"}}></MdOutlinePhoneIphone></i>
                        </div>
                      </Tooltip>
                      <div style={{padding:"10px 20px 10px 20px", marginRight:"20px"}} className={classes.button5} onClick={() => executeResend()}>
                        <span span className={classes.textButton}>ENVIAR <FaPaperPlane></FaPaperPlane></span>
                      </div>
                    </div>
                  </div>
                  : ""
                  }

              </div>
            </div>
          </Footer>
      </Body>
    )
  }


  TabelaResend.propTypes = {
  classes: PropTypes.object,
  setAnchorEl: PropTypes.func,
  selectedUsers: PropTypes.array,
  closeModal: PropTypes.func,
  clientUser: PropTypes.bool,
  value: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(TabelaResend);