/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from "@material-ui/core/Switch";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';

const GeneralConsol = (props) => {
    const {classes, targets, changeGeneralTargets, allTargets, checkBoxGeneral, initDate, handleInitialDate, finalDate, handleFinalDate, toggleGeneralCheckBox, responsible, setResponsible, usersR, clientUser} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const [allOptionsGeneral, setAllOptionsGeneral] = useState([]);

    useEffect(() => {
      const allOp = targets.filter((value, index, self) => self.indexOf(value) === index)

      if(!allOp.includes("Selecionar todos")){
        allOp.unshift("Selecionar todos");
      }
      setAllOptionsGeneral(allOp);
    }, [])


    return (
      <>
        <div>
          <label htmlFor="selectTargets">Período de Consolidação</label>
        </div>
        <div style={{marginTop:"2dvh", width: "100%", display: "flex", flexDirection: "row",  alignItems: "center"}}>
          <div style={{display:"flex", flexDirection:"column",  alignItems: "center"}}> 
            <Autocomplete
              id="targets"
              multiple
              disableClearable
              limitTags={5}
              value={targets?.filter((value) => {return value!="Selecionar todos"})}
              onChange={(event, newValue) => {
                if(newValue.some((value) => value == "Selecionar todos")){
                  let temp = [];
                  temp = allOptionsGeneral?.filter((value) => {
                    return value!="Selecionar todos"
                  })
                  changeGeneralTargets(temp);
                }else{
                  changeGeneralTargets(newValue);
                }
                }
              
              }
              style={fonts.formsFieldHeightModal1}
              size="small"
              options={[... new Set(allOptionsGeneral)]}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      label="Selecionar alvos"
                      variant="outlined"
                      className={classes.textFieldNormal}
                      style={{ marginBottom: '0px',}}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onKeyDown={(event) => {
                          if (
                              event.key ===
                              'Backspace'
                          ) {
                              event.stopPropagation();
                          }
                      }}
                  />
              )}
            />
          </div>
            {!checkBoxGeneral &&
            <div style={{display:"flex", flexDirection:"row"}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  style={{marginLeft:"20px", marginBottom:"15px"}}
                  animateYearScrolling={false}
                  value={initDate}
                  label="Data Inicial"
                  onChange={(date) => handleInitialDate(date)}
                  className={classes.dateField}
                  inputProps={{style: fonts.formsField}}
                  InputLabelProps={{style: fonts.formsLabel}} 
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <KeyboardDatePicker
                  format="dd/MM/yyyy"
                  style={{marginLeft:"20px", marginBottom:"15px"}}
                  animateYearScrolling={false}
                  value={finalDate}
                  label="Data Final"
                  onChange={(date) => handleFinalDate(date)}
                  className={classes.dateField}
                  inputProps={{style: fonts.formsField}}
                  InputLabelProps={{style: fonts.formsLabel}} 
                />
              </MuiPickersUtilsProvider>
            </div>
            }
            <FormControlLabel
              control={(
                <Checkbox
                  checked={checkBoxGeneral}
                  onChange={toggleGeneralCheckBox}
                  value={"dateInitMonit"}
                  color="primary"
                /> )}
                label="Gerar desde o início do monitoramento"
                className={classes.checkboxModal}
            />
            {!clientUser?
              <Autocomplete
                id="responsible"
                value={responsible}
                disableClearable
                onChange={(_, newValue) => setResponsible(newValue)}
                className={classes.selectFieldExtraLargeModal}
                style={fonts.formsFieldHeight5}
                size="small"
                options={usersR!=null? usersR.sort().map((option) => option) : [""]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsável"
                    margin="normal"
                    style={{minWidth:"200px", marginBottom:"10px", marginRight:"20px"}}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              :""
            }
          
        </div>
      </>
    )
  
};

GeneralConsol.propTypes = {
    classes: PropTypes.object.isRequired,
    changeGeneralTargets: PropTypes.func.isRequired,
    allTargets: PropTypes.array.isRequired,
    usersR: PropTypes.array.isRequired,
    checkBoxGeneral: PropTypes.bool.isRequired,
    clientUser: PropTypes.bool,
    initDate: PropTypes.string.isRequired,
    handleInitialDate: PropTypes.func.isRequired,
    finalDate: PropTypes.string.isRequired,
    handleFinalDate: PropTypes.func.isRequired,
    toggleGeneralCheckBox: PropTypes.func.isRequired,
    responsible: PropTypes.array.isRequired,
    setResponsible: PropTypes.func.isRequired,
    targets: PropTypes.array.isRequired,
  };
  
  export default withStyles(styles)(GeneralConsol);