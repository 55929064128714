/* Libraries */
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
/* Material UI */
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ArrowLeft from '../ArrowLeft';
import TableHead from '@material-ui/core/TableHead';
import {
  StyledTableRow,
  StyledCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
} from 'containers/Pages/Table/Table-jss';
import { BiPlus, BiCheck, BiTrash, BiArrowBack } from 'react-icons/bi';
import Edit_icon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
/* Components */
import { useToast } from 'Context/ToastContext';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import { FaArrowLeft } from 'react-icons/fa';
/* Services */
import { patchTarget } from 'services/adminDashboard/target';
import ModalContextProvider from 'Context/ModalContext';


const useStyles = makeStyles(theme => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.menu.mediumGrey,
    },
    toggleGroup: {
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.menu.white,
        backgroundColor: theme.palette.menu.mediumGrey,
      }
    },
    width: "100%"
  },
}));

const ModalHistory = (props) => {
  const { classes, anchorEl, setAnchorEl, width, setReloadMod, data, putData, idAlvo, handleErrataHistory, handleResendHistory, handleRetificationHistory, clientPage} = props;
  const [open, setOpen] = useState(Boolean(anchorEl));
  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [deleteState, setDeleteState] = useState(false);
  const [tableData, settableData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reload, setReload] = useState(false);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [linkError, setLinkError] = useState();
  const id = open ? 'simple-popover' : undefined
  const fonts = fontStyles();
  const colors = useStyles();
  const openToast = useToast();
  const ref = useRef();

  const refEdit = useRef();
  const refAdd = useRef();
  var aux = 0;
  const [editData, setEditData] = useState(data? typeof data === "string"? JSON.parse(data) : data : []);

  const handleClose = () => {
    setOpen(null);
    setReloadMod(true);
  }

  const openErrata = (event) => {
    handleClose();
    handleErrataHistory(event, tableData.empresa+"_"+tableData.plano+"_"+tableData.alvo+"_"+tableData.tipo+"_"+tableData.id_og+"_"+tableData.alvo_id_og+"_"+tableData.nivel+"_"+tableData.d_e+"_"+tableData.env_at+"_"+tableData.risco+"_"+tableData.contato+"_"+tableData.ferramenta+"_"+tableData.observacao+"_"+tableData.intensidade+"_"+tableData.acumulado+"_"+tableData.liberado+"_"+tableData.datahora_liberacao_og +"_"+tableData.conteudo_modificacao+"_"+tableData.meteorologista);

  }

  const openRetification = (event) => {
    handleClose();
    handleRetificationHistory(event, tableData.id+"_"+tableData.empresa+"_"+tableData.plano+"_"+tableData.alvo+"_"+tableData.tipo+"_"+tableData.nivel+"_"+tableData.d_e+"_"+tableData.risco+"_"+tableData.contato+"_"+tableData.ferramenta+"_"+tableData.observacao+"_"+tableData.datahora_liberacao_og+"_"+tableData.liberado+"_"+tableData.meteorologista+"_"+tableData.errata+"_"+tableData.intensidade+"_"+tableData.acumulado+"_"+tableData.id_og);
  }


  console.log(tableData)
  const openResend = (event) => {
    handleClose();
    handleResendHistory(event, tableData.empresa+"_"+tableData.plano+"_"+tableData.alvo+"_"+tableData.tipo+"_"+tableData.id_og+"_"+tableData.alvo_id_og+"_"+tableData.nivel+"_"+tableData.d_e+"_"+tableData.env_at+"_"+tableData.risco+"_"+tableData.contato+"_"+tableData.ferramenta+"_"+tableData.observacao+"_"+tableData.intensidade+"_"+tableData.acumulado+"_"+tableData.liberado+"_"+tableData.datahora_liberacao_og +"_"+tableData.conteudo_modificacao+"_"+tableData.meteorologista+"_"+tableData.tipo.toLowerCase()+"_"+tableData.tipo_resend); 
  }

  const splitMod = (modif) => {
    var aux = "";
    modif!=null? modif.map((value) => aux+= value+" ") : ""
    return aux

  }

  const constDate = (date) =>{
    if(date != null){
      const val_temp = date.split(':');
      const timedays = val_temp[0].split('T');
      const real_date = timedays[0].split('-').reverse().join('/');
      const hours = timedays[1]+":"+val_temp[1]

      return(real_date+" "+hours+"h")

    }else{
      return ""
    }
  };

  useEffect(() => {
    if(reload){
      setReload(false);
    }
  }, [reload]);
  
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
  
    window.addEventListener('resize', handleWindowResize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return(
    <ModalContextProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 300, left: windowSize[0]/2}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      > 

      <Table style={{margin:"0px"}}>
        <TableHead>
          <div style={{width: "100%", height:"100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", padding: "2px 8px 2px 8px", backgroundColor:"#023047"}} >
            <div style={{color:"white", paddingTop:"10px", paddingLeft:"15px", fontSize:"20px"}}>
                <h2>{tableData.empresa} - {tableData.plano} - {tableData.alvo}</h2>
            </div>
          </div>
        </TableHead>
        <TableBody>

          <StyledTableRowDual>
            <StyledCellDual padding="normal" style={fonts.cellContent}><strong>Enviado em: </strong>{tableData.conteudo_modificacao!=""?  <div style={{display:"contents"}}>{constDate(tableData.conteudo_modificacao.datahora_modificacao)} - <s style={{color:"#6D3D06"}}>{tableData.fullData.datahora_criacao? constDate(tableData.fullData.datahora_criacao) : constDate(tableData.fullData.datahora_modificacao)}</s> </div> : tableData.fullData.datahora_criacao? constDate(tableData.fullData.datahora_criacao): constDate(tableData.fullData.datahora_modificacao) }</StyledCellDual>
            <StyledCellDualBorder padding="normal" style={fonts.cellContent}><strong>Liberado em: </strong>{constDate(tableData.liberacao_og)}</StyledCellDualBorder>
          </StyledTableRowDual>

          <StyledTableRowDual>
            <StyledCellDual padding="normal" style={fonts.cellContent}><strong>Duração Estimada: </strong>{tableData.conteudo_modificacao==""? tableData.fullData.duracao :  tableData.conteudo_modificacao.duracao} <s>{tableData.conteudo_modificacao==""? "" : " - " + tableData.duracao }</s></StyledCellDual>
            <StyledCellDualBorder padding="normal" style={fonts.cellContent}><strong>Modificações: </strong>{tableData.conteudo_modificacao!=""? "Sim" : "Não"}</StyledCellDualBorder>
          </StyledTableRowDual>

          <StyledTableRowDual>
            <StyledCellDual padding="normal" style={fonts.cellContent}><strong>Duração: </strong>{tableData.duracao? tableData.duracao : ""}</StyledCellDual>
            <StyledCellDualBorder padding="normal" style={fonts.cellContent}><strong>Status: </strong>{tableData.status==true? "Finalizado" : "Em Alerta"}</StyledCellDualBorder>
          </StyledTableRowDual>

          <StyledTableRowDual>
            <StyledCellDual
                  padding="normal"
                  style={fonts.cellContent}
              >
                <p><strong>Mensagem: </strong>  Alerta de Tempestades com Raios - {tableData.conteudo_modificacao==""? tableData.nivel : tableData.conteudo_modificacao?.nivel}</p>
                <div style={{alignItems: "center", paddingLeft:"88px"}}>
                    <p>Local: {tableData.alvo}</p>
                    <p>Horário: {tableData.conteudo_modificacao==""? tableData.datahora_criacao!=""? tableData.datahora_criacao: tableData.datahora_modificacao : constDate(tableData.conteudo_modificacao.datahora_modificacao) }</p> 
                    <p>Duração Aproximada: {tableData.conteudo_modificacao==""? tableData.fullData.duracao :  tableData.conteudo_modificacao.duracao}</p>
                </div>
            </StyledCellDual>
            <StyledCellDual
                  padding="normal"
                  style={fonts.cellContent}
              >
                {tableData.fullData.conteudo_modificacao!=null? 
                  <div>
                    <div style={{alignItems: "center", paddingLeft:"88px", color:"#6D3D06"}}>
                      {tableData.conteudo_modificacao!="" && tableData.conteudo_modificacao.campos_modificados.includes("nivel")? <p><s>Alerta de Tempestades com Raios - {tableData.nivel} </s></p>: ""}
                      {tableData.conteudo_modificacao!="" ? <p><s>Horário: {tableData.datahora_modificacao!=""? tableData.datahora_modificacao : tableData.datahora_criacao } </s></p> : ""}
                      {tableData.conteudo_modificacao!="" ? <p><s>Duração Aproximada: {tableData.fullData.duracao} </s></p>: ""}
                    </div>
                  </div>
                  :""}          

            </StyledCellDual>
          </StyledTableRowDual>
        </TableBody>
      </Table>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", boxShadow: "4px 8px 8px 10px DarkGrey"}}>
        <div style={{width: "100%", display: "flex", alignItems: "center", marginTop: "20px", paddingBottom:"20px", paddingLeft:"20px"}}>
          <div className={classes.noBorderButton} onClick={() => handleClose()}>
            <ArrowLeft color='black'></ArrowLeft>
          </div>

          {!tableData.errata && !tableData.modificacao&&
            <div style={{display:"flex", marginLeft: "auto"}}>
              {clientPage==undefined || !clientPage&&
              <>
                <div style={{marginLeft:"20px", padding:"5px 20px 5px 20px"}} className={classes.outlineButton1} onClick={(e) => openErrata(e)}>
                  <span span>ERRATA</span>
                </div>
                <div style={{marginLeft:"20px", padding:"5px 20px 5px 20px"}} className={classes.outlineButton1} onClick={(e) => openRetification(e)}>
                  <span span>RETIFICAÇÃO</span>
                </div>
              </>
              }
              <div style={{marginLeft:"20px",padding:"5px 20px 5px 20px", marginRight:"20px"}} className={classes.outlineButton1} onClick={(e) => openResend(e)}>
                <span span>REENVIO</span>
              </div>
            </div>
          }
        </div>
      </div>
      </Popover>
    </ModalContextProvider>
  )
} 

ModalHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func,
  clientPage: PropTypes.bool,
  handleErrataHistory: PropTypes.func,
  handleResendHistory: PropTypes.func, 
  handleRetificationHistory: PropTypes.func,
  anchorEl: PropTypes.object,
  width: PropTypes.string,
  setReloadMod: PropTypes.func,
  data: PropTypes.any,
  putData: PropTypes.any,
  idAlvo: PropTypes.any,
};
  
export default withStyles(styles)(ModalHistory);