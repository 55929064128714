import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import './style.css';
import { useModal } from 'Context/ModalContext';
import { useTarget } from 'Context/TargetContext';
import { PopupItem, PopupSelectTab, PopupTab, SendButton, PopupItemIndvHeader, PopupItemMultHeader, PopupItemContentDischarges, PopupItemContentColumnsLarge, PopupItemContentColumnsSmall, PopupItemHeaderColumnsLarge, PopupItemHeaderColumnsSmall, PopupItemContent, PopupItemIndvContentColumn, PopupItemFooter } from './styled';
import styles from "./popup-jss";
import { patchTarget, getTargetByID, getTargetInfosByTargetID } from 'services/adminDashboard/target';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import fontStyles from "styles/fonts-jss";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import 'moment/locale/pt-br'
import Modal from 'components/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useToast } from 'Context/ToastContext';
import { BiTrash } from 'react-icons/bi';
import { deleteCameras } from 'services/mapsPortal/cameras';
import {useControlPanel} from 'Context/ControlPanelContext';
import Divider from '@material-ui/core/Divider';
import { useActiveStatus } from 'Context/ActiveStatusContext';
import Collapsible from 'components/Collapsible';
import {Checkbox} from '@material-ui/core';
import {MdExpandMore} from 'react-icons/md';
import useTargetHelper from 'hooks/useTargetHelpers';
import { useActivesStatus } from 'Context/ActivesContext';

const useStyles = makeStyles({
  switch_primary: {
    "&.Mui-checked": {
      color: "#000",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#000",
    },
  },
})

const MapPopup = (props) => {
  const [tab, setTab] = useState(0);
  const { planos, toShow: checkboxes} = useTarget();
  const openToast = useToast();
  const { openMonitoringModal, openPredictionModal } = useModal();
  const { toggleReloadCameraPanel } = useControlPanel();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [summaryPopUp, setSummaryPopUp] = useState(false);
  const [targetsData, setTargetsData] = useState();
  const [data, setData] = useState();
  const [plansData, setPlansData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [footer, setFooter] = useState(true);
  const [reloadFooter, setReloadFooter] = useState(true);
  const [reload, setReload] = useState(true);
  const [reload1, setReload1] = useState(false);
  const fonts = fontStyles();
  const switchStyle = useStyles();
  const [automaticAlert, setAutomaticAlert] = useState(props.dataTargets[0]?.alerta_automatico);
  const [statusActive, setStatusActive] = useState(props.dataTargets[0]?.ativos_ativo);
  const [status, setStatus] = useState(props.dataTargets[0]?.status);
  const [reloadCam, setReloadCam] = useState(false);
  const {state, enableStatus, disableStatus} = useActiveStatus();
  const [activeGroupsCheckboxes, setActiveGroupsCheckboxes] = useState([]);
  const [activeGroupsCheckbox, setActiveGroupsCheckbox] = useState(false);
  const [test, setTest] = useState([]);
  const Helper = useTargetHelper();
  const { activesDataStatus, toggleSpecificActives } = useActivesStatus();

  const [putData, setPutData] = useState({
    nome: props.dataTargets[0]?.nome,
    apelido: props.dataTargets[0]?.apelido? props.dataTargets[0]?.apelido : null,
    coordenadas: props.dataTargets[0]?.coordenadas,
    qntd_sms: props.dataTargets[0]?.qntd_sms? props.dataTargets[0]?.qntd_sms : null,
    qntd_email: props.dataTargets[0]?.qntd_email? props.dataTargets[0]?.qntd_email : null,
    qntd_telegram: props.dataTargets[0]?.qntd_telegram? props.dataTargets[0]?.qntd_telegram : null,
    qntd_wpp: props.dataTargets[0]?.qntd_wpp? props.dataTargets[0]?.qntd_wpp : null,
    alerta_automatico: props.dataTargets[0]?.alerta_automatico,
    status: props.dataTargets[0]?.status,
    tipo: props.dataTargets[0]?.tipo,
    antecedencia: props.dataTargets[0]?.antecedencia? props.dataTargets[0]?.antecedencia : null,
    unidade_medida: props.dataTargets[0]?.unidade_medida? props.dataTargets[0]?.unidade_medida : null,
    ativos: props.dataTargets[0]?.ativos? props.dataTargets[0]?.ativos : null,
    ativos_ativo: props.dataTargets[0]?.ativos_ativo,
    buffers_padrao: props.dataTargets[0]?.buffers_padrao,
    buffers: props.dataTargets[0]?.buffers? props.dataTargets[0]?.buffers : null,
    cor: props.dataTargets[0]?.cor? props.dataTargets[0]?.cor : null,
    cameras: props.dataTargets[0]?.cameras? props.dataTargets[0]?.cameras : null
  })

  const moment = require('moment');
  moment.locale('pt-br');

  const identifyTarget = (searchTargetId) => {
    let companyId, planId, targetId;
    planos?.every(company => {
      return company.planos.every((plan) => {
        return plan?.alvos.every((target) => {
          if (target.id_alvo !== searchTargetId) return true
          companyId = company.id;
          planId = plan.id;
          targetId = target.id;
          return false
        })
      })
    })
    return { companyId, planId, targetId };
  }

  function sortData(a, b) {
    if (a?.nome < b?.nome) {
        return -1;
    }
    if (a?.nome > b?.nome) {
        return 1;
    }
    return 0;
}

  const toggleStatusActive = () => {
    /*putData.ativos_ativo = !statusActive;
    putData.ativos = typeof putData.ativos != "object"? JSON.parse(putData.ativos) : putData.ativos;
    putData.coordenadas = typeof putData.coordenadas != "object"? JSON.parse(putData.coordenadas) : putData.coordenadas;
    putData.buffers = typeof putData.buffers != "object"? JSON.parse(putData.buffers) : putData.buffers;

    await patchTarget(props.dataTargets[0].id_alvo, putData).then((response) => {
      if(response.status === 200) {
        setReload(false);
        setStatusActive(!statusActive);
        openToast("success", `Status dos ativos ${!statusActive === true? "ativado" : "desativado"} com sucesso!`);
      }
    }).catch((error) => {
      openToast("error", "Problema ao alterar o status dos ativos, recarregue a página e tente novamente!");
    });*/
    setReload1(true);
    disableStatus();
    setTest(false)
  }

  const toggleAutomaticAlert = async () => {
    /*putData.alerta_automatico = !automaticAlert;
    putData.ativos = typeof putData.ativos != "object"? JSON.parse(putData.ativos) : putData.ativos;
    putData.coordenadas = typeof putData.coordenadas != "object"? JSON.parse(putData.coordenadas) : putData.coordenadas;
    putData.buffers = typeof putData.buffers != "object"? JSON.parse(putData.buffers) : putData.buffers;

    await patchTarget(props.dataTargets[0].id_alvo, putData).then((response) => {
      if(response.status === 200) {
        setReloadFooter(false);
        setAutomaticAlert(!automaticAlert);
        openToast("success", `Alerta automático ${!automaticAlert === true? "ativado" : "desativado"} com sucesso!`);
      }
    }).catch((error) => {
      openToast("error", "Problema ao alterar alerta automático, recarregue a página e tente novamente!");
    });*/
    setReload1(true);
    enableStatus();
    setTest(true)

  }

  const toggleStatus = async () => {
    putData.status = !status;
    putData.ativos = typeof putData.ativos != "object"? JSON.parse(putData.ativos) : putData.ativos;
    putData.coordenadas = typeof putData.coordenadas != "object"? JSON.parse(putData.coordenadas) : putData.coordenadas;
    putData.buffers = typeof putData.buffers != "object"? JSON.parse(putData.buffers) : putData.buffers;

    var ids;
    planos.map((company) => company.planos.map((plan) => plan.alvos.map((target) => {if(target.nome === props.dataTargets[0]?.nome) ids = [company.id, plan.id, target.id]})))

    await patchTarget(props.dataTargets[0].id_alvo, putData).then((response) => {
      if(response.status === 200) {
        if(!status === true){
          if(!checkboxes.get(ids[0], ids[1], ids[2])){
            checkboxes.toggle(ids[0], ids[1], ids[2]);
          }
        }
        else if(!status === false){
          if(checkboxes.get(ids[0], ids[1], ids[2])){
            checkboxes.toggle(ids[0], ids[1], ids[2]);
          }
        }

        setReloadFooter(false);
        setStatus(!status);
        openToast("success", `Status do alvo ${!status === true? "ativado" : "desativado"} com sucesso!`);
      }
    }).catch((error) => {
      openToast("error", "Problema ao alterar o status do alvo, recarregue a página e tente novamente!");
    });
  }

  const formatPlans = () => {
    const targets = [...props.dataTargets]
    targets.sort((a, b) => a.id_plano.localeCompare(b.id_plano))
    const groupTargets = targets.reduce((arr, target, index) => {
      if (!arr.length || arr[arr.length - 1][0]?.id_plano !== target.id_plano)
        arr.push([{...target, id: 0}]);
      else arr[arr.length - 1].push({...target, id: arr[arr.length - 1].length});
      return arr
    }, [])
    const plans = groupTargets.map((targets, index) => {
      const { companyId, planId } = identifyTarget(targets[0]?.id_alvo)
      return {
        ...planos[companyId].planos[planId],
        alvos: targets
      }
    }).sort((a, b) => a.id_empresa_id.localeCompare(b.id_empresa_id))
    const groupPlans = plans.reduce((arr, plan) => {
      if (!arr.length || arr[arr.length - 1][0]?.id_empresa_id !== plan.id_empresa_id){
        arr.push([{...plan, id: 0}]);
      }
      else {
        arr[arr.length - 1].push({...plan, id: arr[arr.length-1].length});
      }
        return arr
    }, [])
    const companies = groupPlans.map((plans, index) => {
      const { companyId } = identifyTarget(plans[0]?.alvos[0]?.id_alvo)
      return {
        ...planos[companyId],
        id: index,
        planos: plans
      }
    })
    // Troque aqui plans.
    return companies;
  }

  const handleOpenMonitoringModal = () => {
    const plans = formatPlans();
    openMonitoringModal(plans)
  }

  const handleOpenPredictionModal = () => {
    const plans = formatPlans();
    openPredictionModal(plans)
  }


  const loadTargetInfos = async () => {
    if(props.dataTargets.length === 1) {
      await getTargetInfosByTargetID(props.dataTargets[0]?.id_alvo).then((response) => {
        if(response[0]?.length > 0){
          setData(response[0][0][0]);
          setLoadingData(false);
        }
        setLoadingData(false);
      }).catch((error) => {
        openToast("error", "Problema ao carregar informações, recarregue a página e tente novamente!");
        setLoadingData(false);
      });
    }
    else if(props.dataTargets.length > 1){
      var aux = [];

      for(let i = 0; i < props.dataTargets.length; i++){
        await getTargetInfosByTargetID(props.dataTargets[i].id_alvo).then((response) => {
          if(response[0]?.length > 0){
            aux.push(response[0][0][0]);
          }
        }).catch((error) => {
          openToast("error", `Problema ao carregar informações do alvo ${props.dataTargets[i].nome}, recarregue a página e tente novamente!`);
          setLoadingData(false);
        });
      }
      if(aux.length > 0) {
        setData(aux);
        setSummaryPopUp(true);
        setLoading(false);
        setLoadingData(false);
      }
      else{
        setLoading(false);
        setLoadingData(false);
      }
    }
  }

  const setPlanName = (data) => {
    if(data  && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0){
          output = output + ", " + data[i].nome;
        }
        else{
          output = data[i].nome;
        }
      }
    }
    return output;
  }

  const setActive = (data) => {
    if(data && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0){
          output = data[i].status? output + ", " + "Ativo" : "Inativo";
        }
        else{
          output = data[i].status? "Ativo" : "Inativo";
        }
      }
    }
    return output;
  }

  const setInitMon = (data) => {
    if(data && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0){
          output = output + ", " + data[i].inicio_contrato.split('-').reverse().join('/');
        }
        else{
          output = data[i].inicio_contrato.split('-').reverse().join('/');
        }
      }
    }
    return output;
  }

  const setHolidays = (data) => {
    if(data && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0){
          output = data[i].feriados? output + ", " + "Sim" : "Não";
        }
        else{
          output = data[i].feriados? "Sim" : "Não";
        }
      }
    }
    return output;
  }

  const setManagers = (data) => {
    if(data && data[0]){
      var output = "";
      for(let i = 0; i < data.length; i++){
        if(i != 0 && data[i].id_permissao === 4){
          output = output + ", " + data[i].nome;
        }
        else if(i === 0 && data[i].id_permissao === 4){
          output = data[i].nome;
        }
      }
    }
    return output;
  }

  const setContacts = (data) => {
    if(data && data[0]){
      var contactData = [];
      data.map((user) => user.prioridade? contactData.push(user) : contactData);

      const sortData = ( a, b ) => {
        if ( a.prioridade.prioridade < b.prioridade.prioridade ){
          return -1;
        }
        if ( a.prioridade.prioridade > b.prioridade.prioridade ){
          return 1;
        }
        return 0;
      }

      contactData.sort(sortData);

      const output = 
        <div style={{display: "flex", flexDirection: "column"}}>
          {contactData.map((user, index) => {
            var phone = "";
            user.prioridade.telefone.map((number, index) => index != user.prioridade.telefone.length -1? phone = phone + number + ", " : phone = phone + number);
            
            if(user.prioridade){
              return (
                <div key={index} style={{display: "flex", flexDirection: "column"}}>
                  <strong>{`${user.prioridade.prioridade} - ${user.nome}`}</strong>
                  <div style={{height: "15px", alignItems: "center", display: "flex"}}>
                    <strong>Horário:&nbsp;</strong>
                    <span>{`${user.prioridade.datafunc_hora_inicio} às ${user.prioridade.datafunc_hora_fim}`}</span>
                  </div>
                  <div style={{height: "15px", alignItems: "center", display: "flex"}}>
                    <strong>Telefone:&nbsp;</strong>
                    <span>{`${phone}`}</span>
                  </div>
                  <div>
                    <strong>Observação: </strong>
                    <span>{`${user.prioridade.observacao}`}</span>
                  </div>
                </div>
              )
            } 
          })}
        </div>
      return output;
    }
    else{
      return "";
    }
  }

  const setServices = (data, type) => {
    var stringfyAlert = data? data?.filter((alert) => alert.status === true) : [];
    var parsedAlert = [];
    stringfyAlert?.length > 0? stringfyAlert?.map((alert) => parsedAlert.push({tipo: alert.tipo, monitoramento: alert.monitoramento})) : "";
    parsedAlert?.length > 0?  parsedAlert?.map((alert) => {
      if(alert.tipo === "Alerta Raio" || alert.tipo === "Aviso de Chuva" || alert.tipo === "Alerta Personalizado" || alert.tipo === "Boletim Diário"){
        alert.monitoramento?.map((days, index) => {
          var aux = [];
          if(days && days.length > 0 && days != null && typeof days[0] === "string"){
            days[0][0] === `"` || days[0][0] === `{`? aux.push(JSON.parse(days)) : aux.push(days);
            alert.monitoramento[index] = aux;
          }
        });
      }
    }) : "";

    const setDays = (data) => {
      var output = "";
      for(let i = 0; i < data?.length; i++) {
        if(i === data?.length - 1 && i != 0){
          output = output + data[i].slice(0, 3);
        }
        else if(i != data?.length - 1){
          output = output + data[i];
        }
        else{
          output = data[i].slice(0, 3);
        }
      }
      return output;
    }

    const setAllDays = (data) => {
      var output = "";

      for(let i = 0; i < data?.length; i++) {
          if(i === data?.length - 1 && i != 0){
            output = output + data[i][0].slice(0, 3);
          }
          else if(i != data?.length - 1){
            output = output + data[i][0];
          }
          else{
            output = data[i][0].slice(0, 3);
          }
        
      }
      return output;
    }

    if(data && data[0]){
      const output = 
        <div style={{display: "flex", flexDirection: "column"}}>
          {parsedAlert.map((service, index1) => service.tipo === type && (service.tipo === "Alerta Raio" || service.tipo === "Aviso de Chuva" || service.tipo === "Alerta Personalizado")?
            service.monitoramento.map((days, index2) =>  
              <div key={index2} style={{display: "flex", flexDirection: "column"}}>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                  {days[0]?.datafunc_dias && days[0]?.datafunc_hora_inicio && days[0]?.datafunc_hora_fim ? (`${setDays(days[0]?.datafunc_dias)} - ${days[0]?.datafunc_hora_inicio} às ${days[0]?.datafunc_hora_fim}`):null}
                </span>
              </div> 
            ) :
            service.tipo === type && service.tipo === "Boletim Diário"?
              <div key={index1} style={{display: "flex", flexDirection: "column"}}>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                  {`${setAllDays(service.monitoramento)}`}
                </span>
              </div> : ""
          )}
        </div>
      return output;
    }
    else{
      return "";
    }
  }

  const handleDeleteCamera = async (cameraID) => {
    await deleteCameras(cameraID.dataCameras[0].id).then((response) => {
      if(response.status === 204){
        toggleReloadCameraPanel();
        openToast("success", "Camera deletada com sucesso!");
        props.onClose();
      }
      else{
        openToast("error", "Problema ao deletar camera. Recarregue a página e tente novamente!");
      }
    })
    .catch((error) => {
      openToast("error", "Problema ao deletar camera. Recarregue a página e tente novamente!");
    })
  }

  useEffect(() => {
    if(!loadingData && !data?.length) {
      loadData();
    }
  }, [data])

  useEffect(() => {
    if(reloadCam){
      setReloadCam(false);
    }
  }, [reloadCam])

  useEffect(() => {
    if(props.dataTargets.length){
      loadTargetInfos();
    }
    else {
      setLoading(false)
      setLoadingData(false)
    }
  }, [])

 
  const loadData = () => {
    const targets = props.dataTargets.map((target, index) => {
      return (
        <PopupItem key={index} style={{width: "75vw", height: "45vh"}}>
          <PopupItemIndvHeader>
              <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer", position: "relative", left: "1%", transform: "translate(-1%, 0)"}} onClick={props.onClose} />
              <div style={{position: "fixed", transform: "translateX(-50%)", left: "50%"}}>
                <h2 style={screen.width < 1025? fonts.popUpHeaderSmall : fonts.popUpHeader}>{target.nome.toUpperCase() + " BUFFER " + target.buffer + "KM"}</h2>
              </div>
          </PopupItemIndvHeader>
          <PopupItemContent>
            <PopupItemIndvContentColumn>
              <span style={screen.width < 769? fonts.popUpContentTopicMedium : fonts.popUpContentTitle}>Plano</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Nome:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setPlanName(data?.plano)}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Empresa:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{data.empresa.nome}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Status:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setActive(data?.plano)}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Início Monitoramento:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setInitMon(data?.plano)}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Feriados:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setHolidays(data?.plano)}</span>
              </div>
            </PopupItemIndvContentColumn>
            <PopupItemIndvContentColumn style={{borderRight: "1px dashed #9b9b9b", borderLeft: "1px dashed #9b9b9b"}}>
              <span style={screen.width < 769? fonts.popUpContentTopicMedium : fonts.popUpContentTitle}>Alvo</span>
              <div style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Antecedência:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{target.antecedencia === null? "Não possui" : target.antecedencia}</span>
              </div>
              <div style={{display: "flex", flexDirection: "row", height: "32px", width: "100%"}}>
                <span style={fonts.popUpSwitch}>Status ativos:</span>
                <Switch
                  checked={test}
                  onChange={() => {test? toggleStatusActive() : toggleAutomaticAlert()}}
                  //value={activeStatus}
                  size="small"
                  classes={{
                    colorPrimary: switchStyle.switch_primary
                  }}
                  color={"primary"} />
              </div>
              <div style={{display: "flex", flexDirection: "row", height: "32px", width: "100%"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Gestores:</p>
                <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>&nbsp;{setManagers(data.usuarios)}</span>
              </div>
              <div style={{ display: "flex", flexDirection: "row", width: "100%"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Contatos de ligação:&nbsp;</p> 
                <div>
                  {setContacts(data.usuarios)}
                </div>
              </div>
            </PopupItemIndvContentColumn>
            <PopupItemIndvContentColumn>
              <span style={screen.width < 769? fonts.popUpContentTopicMedium : fonts.popUpContentTitle}>Serviços</span>
              {data.servicos?.filter((servico) => servico.tipo === "Alerta Raio")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Alerta Raio:&nbsp;</p> 
                  <div>
                    {setServices(data.servicos, "Alerta Raio")}
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Aviso de Chuva")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Aviso de Chuva:&nbsp;</p> 
                  <div>
                    {setServices(data.servicos, "Aviso de Chuva")}
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Boletim Nowcasting")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "20px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Boletim Nowcasting:&nbsp;</p> 
                  <div>
                    <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                      Sim
                    </span>
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Boletim Diário")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Boletim Diário:&nbsp;</p> 
                <div>
                  <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                    Sim
                  </span>
                </div>
              </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Aviso Meteorológico")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Aviso Meteorológico:&nbsp;</p> 
                  <div>
                    <span className='overflowText' style={screen.width < 769? fonts.popUpContentItemSmall : screen.width < 1025? fonts.popUpContentItemMedium : fonts.popUpContentItem}>
                      Sim
                    </span>
                  </div>
                </div>
              }
              {data.servicos?.filter((servico) => servico.tipo === "Alerta Personalizado")?.length > 0 &&
                <div style={{ display: "flex", flexDirection: "row", height: "32px", width: "100%", marginTop: "10px"}}>
                  <p style={screen.width < 769? fonts.popUpContentTopicSmall : screen.width < 1025? fonts.popUpContentTopicMedium : fonts.popUpContentTopic}>Alerta Personalizado:&nbsp;</p> 
                  <div>
                    {setServices(data.servicos, "Alerta Personalizado")}
                  </div>
                </div>
              }
            </PopupItemIndvContentColumn>
          </PopupItemContent>
        </PopupItem>
      );
    });

    setTargetsData(targets);
    setLoading(false);
    setSummaryPopUp(false);
  }

  useEffect(() => {
    setReloadFooter(true)
  }, [reloadFooter])

  useEffect(() => {
    if(reload) setReload1(false)
  }, [reload1])

  useEffect(() => {
    if(!loadingData){
      setReload(true);
      loadData();
    }
  }, [reload])

  const handleTab = (tab) => {
    if(tab === 0) {
      setTab(0);
      setFooter(true);
    }
    else{
      setTab(tab);
      setFooter(false);
    }
  }

  const activeGroups = props.dataActives?.length > 0? 
      Object.values(props.dataActives.reduce((acc, x) => {
        acc[x.grupo] = [...(acc[x.grupo] || []), x ];
        return acc;
      }, {})) : [];

  const toggleActiveCheckbox = (e, id) => {
    setActiveGroupsCheckbox(!activeGroupsCheckbox);
    let aux = activeGroupsCheckboxes.map((data, index) => data?.index === id? {...data, isChecked: activeGroupsCheckbox} : data);
    setActiveGroupsCheckboxes(aux);
  }

  useEffect(() => {
    const arr = activeGroups.map((group, index) => {return {index: index, isChecked: false}})
    setActiveGroupsCheckboxes(arr);
  }, [activeGroups.length])

  if (!loading && !loadingData) {
    var tabs = 0;
    props.dataTargets?.length > 0 ? tabs = tabs + 1 : tabs;
    props.dataDischarges?.length > 0 ? tabs = tabs + 1 : tabs;
    props.dataActives?.length > 0 ? tabs = tabs + 1 : tabs;
    props.dataCameras?.length > 0 ? tabs = tabs + 1 : tabs;
  
    return (
      <Modal onClose={props.onClose} height={"100vh"} center={true}>
        <div className="popup">
          {tabs > 1 && !summaryPopUp? (
            <>
              <div className="popup-tabs">
                {props.dataTargets.length > 0 &&
                  <PopupSelectTab
                    selected={tab === 0}
                    onClick={() => handleTab(0)}
                  >
                    Alvos
                  </PopupSelectTab>
                }
                {props.dataDischarges.length > 0 &&
                  <PopupSelectTab
                    selected={tab === 1}
                    onClick={() => handleTab(1)}
                  >
                    Descargas
                  </PopupSelectTab>
                }
                {props.dataActives.length > 0 &&
                  <PopupSelectTab
                    selected={tab === 2}
                    onClick={() => handleTab(2)}
                  >
                    Ativos
                  </PopupSelectTab>
                }
                {props.dataCameras.length > 0 &&
                  <PopupSelectTab
                    selected={tab === 3}
                    onClick={() => handleTab(3)}
                  >
                    Camera
                  </PopupSelectTab>
                }
              </div>
              <PopupTab>
                {tab === 0?
                  targetsData 
                :
                  tab === 1?
                    <PopupItem style={{width: "35vw", height: "50vh"}}>
                      <PopupItemIndvHeader>
                        <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer" }} onClick={props.onClose} />
                      </PopupItemIndvHeader>
                      <PopupItemContentDischarges>
                        <PopupItemIndvContentColumn>
                          {props.dataDischarges.map((discharge, index) => [(
                            <div key={index} style={{width: "100%"}}>
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Rede:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {discharge.fonte === 'Solo(BrasilDat)' ? 'BrasilDat' : 'GLM'}</span>
                                  {discharge.fonte === 'Solo(BrasilDat)' &&
                                  <div>
                                    <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Tipo: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.tipo === 1 ? 'Intra Nuvem' : 'Nuvem Solo'}</span>
                                  </div>
                                  }
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Detecção:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.datahora_descargas).add(3, 'hours').format('HH:mm:ss')}</span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Aquisição:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.created).add(3, 'hours').format('HH:mm:ss')}</span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Latitude:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[1].toFixed(4)}º</span>
                              </div>
                              <div style={{ display: "flex", flexDirection: "row", width: "100%",borderBottom:"1.5px solid", marginBottom: "10px"}}>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Longitude: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[0]?.toFixed(4)}º</span>
                              </div>
                            </div>
                          )])}
                        </PopupItemIndvContentColumn>
                      </PopupItemContentDischarges>
                    </PopupItem> :
                    tab === 2?
                      <PopupItem style={{width: "35vw", height: "50vh"}}>
                        <PopupItemIndvHeader>
                          <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer", position: "relative", left: "1%", transform: "translate(-1%, 0)"}} onClick={props.onClose} />
                        </PopupItemIndvHeader>
                        <PopupItemContentDischarges>
                          <PopupItemIndvContentColumn >
                            {activeGroups.map((group, index) => {
                              return(
                                <Collapsible
                                  key={index}
                                  // isOpen={true}
                                  isOpen={activeGroupsCheckboxes[index].isChecked}
                                  main={(
                                    <div style={{alignItems: "center", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}} onClick={(e) => toggleActiveCheckbox(e, index)}>
                                        <Checkbox
                                          //onChange={() => checkboxes.toggle(company.id, plan.id)}
                                          //checked={checkboxes.get(company.id, plan.id)}
                                          style={{color: "#1B1B1B"}}
                                        />
                                      <div style={{alignItems: "center", display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                        <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>{activeGroups[index][0].grupo}</p>
                                        <MdExpandMore style={{fontSize: '32px'}}/>

                                      </div>
                                    </div>
                                  )}
                                >
                                  {activeGroups[index].sort(sortData).map((active, index) => {
                                    return(
                                      <div style={{width: "100%"}} key={index}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Ativo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {active.Nome || active.nome}</span>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Alvo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {Helper.getTarget(active.id_alvo)}</span>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Empresa:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {active.empresa || active.Empresa}</span>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                          <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Status: </p> 
                                          
                                          <Switch
                                            onChange={() => toggleSpecificActives(active.id_ativo)}
                                            checked={
                                              activesDataStatus?.features.find(
                                                (item) => item.properties.id_ativo === active.id_ativo
                                              )?.properties.status || false
                                            }
                                            value={status}
                                            classes={{
                                              root: styles.root,
                                              track: styles.track,
                                            }}
                                            className={styles.thumb}
                                            size="small"
                                            color={"primary"}
                                          />
                                        </div>
                                        <Divider style={{marginBottom: "5px"}}/>
                                      </div>
                                    )
                                  })}
                                </Collapsible >
                              )})
                            }
                            
                          
                            
                          </PopupItemIndvContentColumn>
                        </PopupItemContentDischarges>
                      </PopupItem> :
                     <PopupItem style={{width: "35vw", height: "25vh"}}>
                      <PopupItemIndvHeader>
                        <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer" }} onClick={props.onClose} />
                        <div style={{position: "fixed", transform: "translateX(-50%)", left: "50%"}}>
                          <h2 style={screen.width < 1025? fonts.popUpHeaderSmall : fonts.popUpHeader}>{props.dataCameras[0].nome? props.dataCameras[0].nome.toUpperCase() : ""}</h2>
                        </div>
                        <div className={props.classes.outlineButton} style={{position: "relative", transform: "translateX(-90%)", left: "90%"}} onClick={() => handleDeleteCamera(props)}>  
                          <BiTrash style={{width: "18px", height: "18px"}} className={props.classes.outlineButtonIcon}/>
                        </div>
                      </PopupItemIndvHeader>
                      <PopupItemContentDischarges>
                        <PopupItemIndvContentColumn>
                          <div style={{width: "100%", display: "flex", flexDirection: "column", position: "relative"}}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                              <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Link:&nbsp;</p><a style={fonts.mapSidebarCamDescripitionLinkContent} href={props.dataCameras[0].link} target="_blank" rel="noopener noreferrer">{props.dataCameras[0].link}</a>
                            </div>
                                  
                          </div>
                        </PopupItemIndvContentColumn>
                      </PopupItemContentDischarges>
                    </PopupItem>
              }
              </PopupTab>
            </>
          ) : (
              props.dataActives.length === 1 ?
                <PopupItem style={{width: "35vw", height: "50vh"}}>
                  <PopupItemIndvHeader>
                    <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer", position: "relative", left: "1%", transform: "translate(-1%, 0)"}} onClick={props.onClose} />
                  </PopupItemIndvHeader>
                  <PopupItemContentDischarges>
                    <PopupItemIndvContentColumn>
                        <div style={{width: "100%"}}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Ativo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {props.dataActives[0].Nome || props.dataActives[0].nome}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Alvo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {Helper.getTarget(props.dataActives[0].id_alvo)}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Empresa:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {props.dataActives[0].empresa || props.dataActives[0].Empresa}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Status: </p> 
                            
                            <Switch
                              onChange={() => toggleSpecificActives(props.dataActives[0].id_ativo)}
                              checked={
                                activesDataStatus?.features.find(
                                  (item) => item.properties.id_ativo === props.dataActives[0].id_ativo
                                )?.properties.status || false
                              }
                              value={status}
                              classes={{
                                root: styles.root,
                                track: styles.track,
                              }}
                              className={styles.thumb}
                              size="small"
                              color={"primary"}
                            />
                          </div>
                      </div>
                    </PopupItemIndvContentColumn>
                  </PopupItemContentDischarges>
                </PopupItem> :
            <PopupTab>
              {!summaryPopUp && targetsData}
              {summaryPopUp?
                <PopupItem style={{width: "80vw", height: "40vh"}}>
                  <PopupItemMultHeader>
                    <PopupItemHeaderColumnsLarge>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Empresa</h2>
                    </PopupItemHeaderColumnsLarge>
                    <PopupItemHeaderColumnsLarge>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Plano</h2>
                    </PopupItemHeaderColumnsLarge>
                    <PopupItemHeaderColumnsSmall>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Status plano</h2>
                    </PopupItemHeaderColumnsSmall>
                    <PopupItemHeaderColumnsLarge>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Alvo</h2>
                    </PopupItemHeaderColumnsLarge>
                    <PopupItemHeaderColumnsSmall>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderDischargesSmall : fonts.popUpHeaderDischarges}>Alerta Raio</h2>
                    </PopupItemHeaderColumnsSmall>
                  </PopupItemMultHeader>
                  <PopupItemContent>
                    <PopupItemContentColumnsLarge>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className={data.empresa.nome.length > 30? "popup-mult-item-div-overflow" : "popup-mult-item-div"}>{data.empresa.nome}</span>
                      )])}
                    </PopupItemContentColumnsLarge>
                    <PopupItemContentColumnsLarge>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{data.plano[0]?.nome}</span>
                      )])}
                    </PopupItemContentColumnsLarge>
                    <PopupItemContentColumnsSmall>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{data.plano[0]?.status === 0? "Inativo" : data.plano[0]?.status === 1? "Ativo" : "Suspenso"}</span>
                      )])}
                    </PopupItemContentColumnsSmall>
                    <PopupItemContentColumnsLarge>
                      {props.dataTargets.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{data.nome}</span>
                      )])}
                    </PopupItemContentColumnsLarge>
                    <PopupItemContentColumnsSmall>
                      {data.map((data, index) => [(
                        <span key={index} style={screen.width < 1025? fonts.popUpContentItemDischargesSmall : fonts.popUpContentItemDischarges} className="popup-mult-item-div">{'setServices(data.servicos) != "Não contemplado"? "Ativo" : "Inativo"'}</span>
                      )])}
                    </PopupItemContentColumnsSmall>
                  </PopupItemContent>
                </PopupItem> : ""
              }
              {!summaryPopUp && !targetsData && props.dataDischarges?.length > 0 && 
                <PopupItem style={{width: "35vw", height: "50vh"}}>
                  <PopupItemIndvHeader>
                    <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer" }} onClick={props.onClose} />
                  </PopupItemIndvHeader>
                  <PopupItemContentDischarges>
                    <PopupItemIndvContentColumn>
                      {props.dataDischarges.map((discharge, index) => [(
                        <div key={index} style={{width: "100%"}}>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Rede:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {discharge.fonte === 'Solo(BrasilDat)' ? 'BrasilDat' : 'GLM'}</span>
                              {discharge.fonte === 'Solo(BrasilDat)' &&
                              <div>
                                <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Tipo: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.tipo === 1 ? 'Intra Nuvem' : 'Nuvem Solo'}</span>
                              </div>
                              }
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Detecção:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.datahora_descargas).add(3, 'hours').format('HH:mm:ss')}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Hora de Aquisição:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{moment(discharge.created).add(3, 'hours').format('HH:mm:ss')}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Latitude:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[1].toFixed(4)}º</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row", width: "100%", borderBottom:"1.5px solid", marginBottom: "10px"}}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Longitude: </p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}>{discharge.coordinates[0]?.toFixed(4)}º</span>
                          </div>
                        </div>
                      )])}
                    </PopupItemIndvContentColumn>
                  </PopupItemContentDischarges>
                </PopupItem>
              }
              {!summaryPopUp && !targetsData && props.dataActives?.length > 0 &&
                <PopupItem style={{width: "35vw", height: "50vh"}}>
                <PopupItemIndvHeader>
                  <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer", position: "relative", left: "1%", transform: "translate(-1%, 0)"}} onClick={props.onClose} />
                </PopupItemIndvHeader>
                <PopupItemContentDischarges>
                  <PopupItemIndvContentColumn>
                    {
                      props.dataActives && props.dataActives.length > 0 && props.dataActives.sort(sortData).map((active, index) => {return(
                        <div style={{width: "100%"}} key={index}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Ativo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {active.Nome || active.nome}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Alvo:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {Helper.getTarget(active.id_alvo)}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Empresa:</p> <span style={screen.width < 769? fonts.popUpContentItemLarge : fonts.popUpContentItem}> {active.empresa || active.Empresa}</span>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Status: </p> 
                            
                            <Switch
                              onChange={() => toggleSpecificActives(active.id_ativo)}
                              checked={
                                activesDataStatus?.features.find(
                                  (item) => item.properties.id_ativo === active.id_ativo
                                )?.properties.status || false
                              }
                              value={status}
                              classes={{
                                root: styles.root,
                                track: styles.track,
                              }}
                              className={styles.thumb}
                              size="small"
                              color={"primary"}
                            />
                          </div>
                          <Divider style={{marginBottom: "5px"}}/>
                        </div>
                      )})
                    }
                  </PopupItemIndvContentColumn>
                </PopupItemContentDischarges>
              </PopupItem>}
              {props.dataCameras?.length === 1 && (
                <PopupItem style={{width: "35vw", height: "25vh"}}>
                  <PopupItemIndvHeader>
                    <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "white", cursor: "pointer" }} onClick={props.onClose} />
                    <div style={{position: "fixed", transform: "translateX(-50%)", left: "50%"}}>
                      <h2 style={screen.width < 1025? fonts.popUpHeaderSmall : fonts.popUpHeader}>{props.dataCameras[0].nome? props.dataCameras[0].nome.toUpperCase() : ""}</h2>
                    </div>
                    <div className={props.classes.outlineButton} style={{position: "relative", transform: "translateX(-90%)", left: "90%"}} onClick={() => handleDeleteCamera(props)}>  
                      <BiTrash style={{width: "18px", height: "18px"}} className={props.classes.outlineButtonIcon}/>
                    </div>
                  </PopupItemIndvHeader>
                  <PopupItemContentDischarges>
                    <PopupItemIndvContentColumn>
                      <div style={{width: "100%", display: "flex", flexDirection: "column", position: "relative"}}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                          <p style={screen.width < 769? fonts.popUpContentTopicLarge : fonts.popUpContentTopic}>Link:&nbsp;</p><a style={fonts.mapSidebarCamDescripitionLinkContent} href={props.dataCameras[0].link} target="_blank" rel="noopener noreferrer">{props.dataCameras[0].link}</a>
                        </div>
                        
                      </div>
                    </PopupItemIndvContentColumn>
                  </PopupItemContentDischarges>
                </PopupItem>
              )}
            </PopupTab>
          )}
          {props.dataTargets.length > 0 && footer && reloadFooter && (
            <PopupItemFooter>
              <div style={{ left: "20%", position: "fixed", width: "20%"}}>
                {summaryPopUp?
                  <ArrowBackIcon style={{ width: "25px", height: "25px ", color: "black", cursor: "pointer" }} onClick={props.onClose} />
                :
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                      <Switch
                        checked={automaticAlert}
                        onChange={() => toggleAutomaticAlert()}
                        value={automaticAlert}
                        size="small"
                        classes={{
                          colorPrimary: switchStyle.switch_primary
                        }}
                        color={"primary"} />
                      <span style={fonts.popUpSwitch}>Alerta automático</span>
                    </div>

                    <div>
                      <Switch
                       checked={status}
                       onChange={() => toggleStatus()}
                       value={status}
                       size="small"
                       classes={{
                         colorPrimary: switchStyle.switch_primary
                       }}
                       color={"primary"} />
                      <span style={fonts.popUpSwitch}>Status Alvo</span>
                    </div>
                  </div>
                }
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", justifyContent: "flex-end"}}>
                <SendButton onClick={handleOpenMonitoringModal} style={fonts.popUpButton}>MONITORAMENTO</SendButton>
                <div style={{ marginLeft: "5%" }}>
                  <SendButton onClick={handleOpenPredictionModal} style={fonts.popUpButton}>PREVISÃO</SendButton>
                </div>
              </div>
            </PopupItemFooter>
          )}
        </div>
      </Modal>
    );
  }
  else if(loading){
    return(
      <CircularProgress style={{marginLeft: "calc(45%)", marginTop: "calc(20%)"}} size={90} thickness={4} color="primary"/>
    )
  }
};

MapPopup.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  onClose: PropTypes.func,
  dataDischarges: PropTypes.array,
  dataTargets: PropTypes.array,
  dataActives: PropTypes.array,
  dataCameras: PropTypes.array,
  classes: PropTypes.object
};

export default withStyles(styles)(MapPopup);