import { useState, useEffect } from 'react';

// Libs
import p from 'prop-types';
import 'moment/locale/pt-br';
// Components
import { Column, Row } from 'components/Flex';
import {
    ItemContent,
    TargetItem,
    Span,
} from '../../../../Sidebar/PlansTab/styled';
import { Checkbox } from '@material-ui/core';
import { InfoDiv, Property } from '../../../../Sidebar/styled';
import Collapsible from 'components/Collapsible';
import fontStyles from 'styles/fonts-jss';
// Icons
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
// Hooks
import { useToast } from 'Context/ToastContext';
import useToggle from 'hooks/useToggle';
// Services
import { getTargetInfosByTargetID } from 'services/adminDashboard/target';

const TargetInfo = ({
    company,
    plan,
    target,
    checkboxes,
    checked,
    tab,
    loadingLeftSide,
    setLoadingLeftSide,
    setReloadLeftSide,
}) => {
    const [show, toggleShow] = useToggle(true);
    const [info, setInfo] = useState(undefined);
    const moment = require('moment');
    moment.locale('pt-br');
    const fonts = fontStyles();
    const [loading, setLoading] = useState(true);
    const [targetData, setTargetData] = useState([]);
    const [loadingTargetData, setLoadingTargetData] = useState(0);

    useEffect(() => {
        if (show && loadingTargetData === 0) loadTargetData();
    }, [show, loadingTargetData]);

    /*useEffect(() => {
    if(targetData.length > 0){
      let aux = loadingLeftSide;
      aux.push(true);
      setLoadingLeftSide(aux);
      setReloadLeftSide(true);
    }
    else{
      let aux = loadingLeftSide;
      aux.pop(true);
      aux.pop(false);
      setLoadingLeftSide(aux);
      setReloadLeftSide(true);
    }
  }, [targetData])*/

    const loadTargetData = async () => {
        if (target.id_alvo) {
            await getTargetInfosByTargetID(target.id_alvo)
                .then((response) => {
                    if (response[0].length > 0) {
                        setTargetData(response[0][0][0]);
                        setLoadingTargetData(1);
                    } else {
                        setLoadingTargetData(2);
                    }
                })
                .catch((error) => {
                    setLoadingTargetData(2);
                });
        }
    };

    const setManagers = (data) => {
        if (data && data[0]) {
            var output = '';
            for (let i = 0; i < data.length; i++) {
                if (i != 0 && data[i].id_permissao === 4) {
                    output = output + ', ' + data[i].nome;
                } else if (i === 0 && data[i].id_permissao === 4) {
                    output = data[i].nome;
                }
            }
        }
        return output;
    };

    const setContacts = (data) => {
        if (data && data[0]) {
            var contactData = [];
            data.map((user) =>
                user.prioridade ? contactData.push(user) : contactData,
            );

            const sortData = (a, b) => {
                if (a.prioridade.prioridade < b.prioridade.prioridade) {
                    return -1;
                }
                if (a.prioridade.prioridade > b.prioridade.prioridade) {
                    return 1;
                }
                return 0;
            };

            contactData.sort(sortData);

            const output = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {contactData.map((user, index) => {
                        var phone = '';
                        user.prioridade.telefone.map((number, index) =>
                            index != user.prioridade.telefone.length - 1
                                ? (phone = phone + number + ', ')
                                : (phone = phone + number),
                        );

                        if (user.prioridade) {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0px',
                                    }}
                                >
                                    <span
                                        style={
                                            fonts.mapSidebarTargetDescripitionItem
                                        }
                                    >{`${user.prioridade.prioridade} - ${user.nome}`}</span>
                                    <div
                                        style={{
                                            height: '15px',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <span
                                            style={
                                                fonts.mapSidebarTargetDescripitionItem
                                            }
                                        >
                                            Horário:&nbsp;
                                        </span>
                                        <span
                                            style={
                                                fonts.mapSidebarTargetDescripitionContent
                                            }
                                        >{`${user.prioridade.datafunc_hora_inicio} às ${user.prioridade.datafunc_hora_fim}`}</span>
                                    </div>
                                    <div
                                        style={{
                                            height: '15px',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <span
                                            style={
                                                fonts.mapSidebarTargetDescripitionItem
                                            }
                                        >
                                            Telefone:&nbsp;
                                        </span>
                                        <span
                                            style={
                                                fonts.mapSidebarTargetDescripitionContent
                                            }
                                        >{`${phone}`}</span>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}
                                    >
                                        <span
                                            style={
                                                fonts.mapSidebarTargetDescripitionItem
                                            }
                                        >
                                            Observação:{' '}
                                        </span>
                                        <div
                                            style={{
                                                padding: '0 0 0 10px',
                                                display: 'flex',
                                            }}
                                        >
                                            <span
                                                style={
                                                    fonts.mapSidebarTargetDescripitionContent
                                                }
                                            >{`${user.prioridade.observacao}`}</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
            );
            return output;
        } else {
            return '';
        }
    };

    const setServices = () => {
        var stringfyAlert = targetData.servicos? targetData.servicos?.filter((alert) => alert.status === true) : [];
        var parsedAlert = [];
        stringfyAlert?.length > 0? stringfyAlert?.map((alert) => parsedAlert.push({tipo: alert.tipo, monitoramento: alert.monitoramento})) : "";
        parsedAlert?.length > 0?  parsedAlert?.map((alert) => {
          if(alert.tipo === "Boletim Diário"){
            alert.monitoramento.map((days, index) => {
              var aux = [];
              if(typeof days[0] === "string"){
                days[0][0] === `"`? aux.push(JSON.parse(days)) : aux.push(days);
                alert.monitoramento[index] = aux;
              }                

            });
          }
        }) : "";

        const setAllDays = (data) => {
          var output = "";

          for(let i = 0; i < data?.length; i++) {
              if(i === data?.length - 1 && i != 0){
                output = output + data[i][0].slice(0, 3);
              }
              else if(i != data?.length - 1){
                output = output + data[i][0];
              }
              else{
                output = data[i][0].slice(0, 3);
              }
            
          }
          return output;
        }

        if(targetData?.servicos && targetData.servicos.length > 0){
          const output = tab === 0?
                <div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <span style={fonts.mapSidebarTargetDescripitionItem}>Boletim Diário:</span>
                        <span className='overflowText' style={fonts.popUpContentItemSmall}>&nbsp;{parsedAlert.filter((data) => data.tipo === "Boletim Diário")?.length> 0? setAllDays(parsedAlert.filter((data) => data.tipo === "Boletim Diário")[0].monitoramento) : "Não contemplado"}</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <span style={fonts.mapSidebarTargetDescripitionItem}>Boletim Nowcasting:</span>
                        <span className='overflowText' style={fonts.popUpContentItemSmall}>&nbsp;{parsedAlert.filter((data) => data.tipo === "Boletim Nowcasting")?.length> 0? "Sim" : "Não contemplado"}</span>
                    </div>
                </div> :
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <span style={fonts.mapSidebarTargetDescripitionItem}>Aviso Meteorológico:</span>
                    <span className='overflowText' style={fonts.popUpContentItemSmall}>&nbsp;{parsedAlert.filter((data) => data.tipo === "Aviso Meteorológico")?.length> 0? "Sim" : "Não contemplado"}</span>
                </div> 
            
            return output;
        }
        else{
            return "";
        }
    };

    if (loadingTargetData === 1 && !loadingLeftSide.length) {
        return (
            <Collapsible
                key={`sidebaralvo-${target.id}`}
                // isOpen={true}
                isOpen={show}
                main={
                    <TargetItem ml="32px" checked={checked}>
                        {targetData.plano[0].nome != target.nome && (
                            <Checkbox
                                label={target.nome}
                                onChange={() =>
                                    checkboxes.toggle(
                                        company.id,
                                        plan.id,
                                        target.id,
                                    )
                                }
                                checked={checkboxes.get(
                                    company.id,
                                    plan.id,
                                    target.id,
                                )}
                                style={{color: "#1B1B1B"}}
                            />
                        )}
                        <ItemContent>
                            {targetData.plano[0].nome != target.nome && (
                                <>
                                    <Span ml="32px">{target.nome}</Span>

                                    <Row>
                                        {show ? (
                                            <MdExpandMore
                                                style={{ fontSize: '22px' }}
                                                onClick={toggleShow}
                                            />
                                        ) : (
                                            <MdChevronRight
                                                style={{ fontSize: '22px' }}
                                                onClick={toggleShow}
                                            />
                                        )}
                                    </Row>
                                </>
                            )}
                        </ItemContent>
                    </TargetItem>
                }
            >
                <Column align="flex-start" margin="0px 0px 0px 32px">
                    <InfoDiv checked={checked}>
                        <Property>
                            <span
                                style={fonts.mapSidebarTargetDescripitionItem}
                            >
                                Status plano:{' '}
                            </span>
                            <div>
                                <span
                                    style={
                                        fonts.mapSidebarTargetDescripitionContent
                                    }
                                >
                                    {targetData.plano[0].status === 1
                                        ? 'Ativo'
                                        : targetData.plano[0] === 0
                                        ? 'Inativo'
                                        : 'Supenso'}
                                </span>
                            </div>
                        </Property>
                        <Property>
                            <span
                                style={fonts.mapSidebarTargetDescripitionItem}
                            >
                                Início monitoramento:{' '}
                            </span>
                            <div>
                                <span
                                    style={
                                        fonts.mapSidebarTargetDescripitionContent
                                    }
                                >
                                    {targetData.plano[0].inicio_contrato
                                        .split('-')
                                        .reverse()
                                        .join('/')}
                                </span>
                            </div>
                        </Property>
                        <Property>
                            <span
                                style={fonts.mapSidebarTargetDescripitionItem}
                            >
                                Alerta automático:{' '}
                            </span>
                            <div>
                                <span
                                    style={
                                        fonts.mapSidebarTargetDescripitionContent
                                    }
                                >
                                    {target.alerta_automatico
                                        ? 'Ativo'
                                        : 'Inativo'}
                                </span>
                            </div>
                        </Property>
                        <Property>
                            <span
                                style={fonts.mapSidebarTargetDescripitionItem}
                            >
                                Antecedência:
                            </span>
                            <div>
                                <span
                                    style={
                                        fonts.mapSidebarTargetDescripitionContent
                                    }
                                >
                                    {target.antecedencia}
                                </span>
                            </div>
                        </Property>
                        <Property>
                            {'setServices()'}
                        </Property>
                        <Property>
                            <span
                                style={fonts.mapSidebarTargetDescripitionItem}
                            >
                                Gestores:
                            </span>
                            <div>
                                {
                                    <span
                                        style={
                                            fonts.mapSidebarTargetDescripitionContent
                                        }
                                    >
                                        {setManagers(targetData.usuarios)}
                                    </span>
                                }
                            </div>
                        </Property>
                        <Property>
                            <span
                                style={fonts.mapSidebarTargetDescripitionItem}
                            >
                                Contatos:
                            </span>
                            <div>{setContacts(targetData.usuarios)}</div>
                        </Property>
                    </InfoDiv>
                </Column>
            </Collapsible>
        );
    } else if (loadingTargetData === 2) {
        return (
            <span
                style={fonts.mapSidebarTargetDescripitionItem}
            >{`Problema ao carregar informações do alvo ${target.nome}, recarregue a página e tente novamente!`}</span>
        );
    } else {
        return (
            <span
                style={fonts.alertsPanelLoadingTarget}
            >{`Carregando alvo ${target.nome}...`}</span>
        );
    }
};

TargetInfo.propTypes = {
    target: p.any,
    plan: p.any,
    company: p.any,
    checkboxes: p.any,
    checked: p.bool,
    tab: p.number,
    loadingLeftSide: p.any,
    setLoadingLeftSide: p.func,
    setReloadLeftSide: p.func,
};
export default TargetInfo;
