/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from "@material-ui/core/Switch";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';
import TargetTab from '../../pages/MapsPortal/components/AlertsPanel/component/TargetTab';
import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from 'components/TabPanel';
import {
  StyledTableRow,
  StyledCellView,
  StyledNoBorderCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
  StyledTableRowLightBorder,
  StyledTableRowNoBorder,
  StyledTableRowVeryLightBorder,
} from 'containers/Pages/Table/Table-jss';

const EspecificConsol = (props) => {
    const {classes, data, manipulateGraphicsTargets, initialMonitoringDate, handleInitialMonitoring, allTargets, networkTargetsGeneral, setNetworkTargetsGeneral, getNetworkTarget, addNetworkTarget, optionsNetwork, multiplePeriods, checkAllPeriodsBoxesInit, multipleChecksInit, checkAllBoxesInit, generalCheckBox} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const [functionSelect, setFunctionSelect] = useState([]);
    const [allEspecificOptions, setAllEspecificOptions] = useState([]);

    useEffect(() => {

      if(networkTargetsGeneral==""||networkTargetsGeneral==" "|| networkTargetsGeneral==null){
        setNetworkTargetsGeneral(optionsNetwork[0]) 
      }

      let allOp = allTargets;
      if(!allOp.includes("Selecionar todos")){
        allOp.unshift("Selecionar todos");
      }
      setAllEspecificOptions(allOp);

    }, []);

    return (
      <>
        <StyledNoBorderCellView padding="normal" style={fonts.cellContent}>
          <div style={{fontWeight: "normal", marginBottom:"10px"}}>
            <label htmlFor="selectTargets2">Gráficos por Alvo</label>
          </div>
          <div style={{display:"flex", flexDirection:"column",  alignItems: "center"}}> 
            <Autocomplete
              id={"targets_"+data}
              multiple={true}
              disableClearable
              limitTags={5}
              value={data?.filter((value) => {return value!="Selecionar todos"})}
              onChange={(event, newValue) => {
                if(newValue.some((value) => value == "Selecionar todos")){
                  let temp = [];
                  temp = allTargets?.filter((value) => {
                    return value!="Selecionar todos"
                  })
                  manipulateGraphicsTargets(temp);
                }else{
                  manipulateGraphicsTargets(newValue);
                }
                }
              
              }
              style={fonts.formsFieldHeightModal1}
              size="small"
              options={[... new Set(allEspecificOptions)]}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      label="Selecionar alvos"
                      variant="outlined"
                      id="selectTargets2"
                      className={classes.textFieldNormal}
                      size="small"
                      style={{ marginBottom: '0px',}}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onKeyDown={(event) => {
                          if (
                              event.key ===
                              'Backspace'
                          ) {
                              event.stopPropagation();
                          }
                      }}
                  />
              )}
            />
          </div>
        </StyledNoBorderCellView>
        <div>
          <StyledNoBorderCellView padding="normal" style={fonts.cellContent}>
            <Autocomplete
                id="network"
                value={networkTargetsGeneral}
                disableClearable
                onChange={(_, newValue) => setNetworkTargetsGeneral(newValue)}
                className={classes.textDown}
                style={fonts.formsFieldHeight5}
                size="small"
                options={optionsNetwork}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecionar Rede"
                    margin="normal"
                    style={{minWidth:"200px"}}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
            />
          </StyledNoBorderCellView>
          <StyledNoBorderCellView padding="normal" style={fonts.cellContent}>
            <FormControlLabel
                control={(
                  <Checkbox
                    checked={multiplePeriods}
                    onChange={()=>checkAllPeriodsBoxesInit()}
                    value={"GenerateInit"}
                    color="primary"
                  /> )}
                  label="Gerar gráficos para o mesmo período"
                  style={fonts.formsField}
                  className={classes.checkboxModalDown}
            />
          </StyledNoBorderCellView>
          {!generalCheckBox&&
            <StyledNoBorderCellView padding="normal" style={fonts.cellContent}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={initialMonitoringDate}
                    onChange={()=>handleInitialMonitoring(!initialMonitoringDate)}
                    value={"GenerateInitAll"}
                    color="primary"
                  /> )}
                  label="Gerar desde o início do monitoramento"
                  style={fonts.formsField}
                  className={classes.checkboxModalDown}
              />
            </StyledNoBorderCellView>
          }
        </div>
      </>
    )
  
};

EspecificConsol.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.any.isRequired,
    manipulateGraphicsTargets: PropTypes.func.isRequired,
    networkTargetsGeneral: PropTypes.string,
    initialMonitoringDate: PropTypes.bool.isRequired,
    handleInitialMonitoring: PropTypes.func.isRequired,
    generalCheckBox: PropTypes.bool.isRequired,
    setNetworkTargetsGeneral: PropTypes.func,
    allTargets: PropTypes.array.isRequired,
    getNetworkTarget: PropTypes.func.isRequired,
    addNetworkTarget: PropTypes.func.isRequired,
    optionsNetwork: PropTypes.array.isRequired,
    multiplePeriods: PropTypes.array.isRequired,
    checkAllPeriodsBoxesInit: PropTypes.func.isRequired,
    multipleChecksInit: PropTypes.array.isRequired,
    checkAllBoxesInit: PropTypes.func.isRequired,

  };
  
  export default withStyles(styles)(EspecificConsol);