import React, { useState, useEffect }  from 'react';
import { withStyles } from "@material-ui/core/styles";
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
import PropTypes from "prop-types";
import { Tooltip } from '@material-ui/core';

const TooltipComponent = ({
  classes,
  text,
  children,
  xAxis,
  yAxis,
  position,
  enterDelay,
  leaveDelay,
  onClose,
  onOpen,
  open: propOpen,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loadingTextMessage, setLoadingTextMessage] = useState(true);
  const [textMessage, setTextMessage] = useState("");
  const fonts = fontStyles();
  const [xAxisTemp, setXAxisTemp] = useState(xAxis && typeof xAxis === "string"? xAxis : "0%");
  const [yAxisTemp, setYAxisTemp] = useState(yAxis && typeof yAxis === "string"? yAxis : "120%");
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (onOpen) onOpen();
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (onClose) onClose();
  };

  const formatTextMessage = () => {
    const slicedMessage = text?.split('\n');
    setTextMessage(slicedMessage);
    setLoadingTextMessage(false);
  };

  useEffect(() => {
    setXAxisTemp(xAxis && typeof xAxis === "string"? xAxis : "0%");
    setYAxisTemp(yAxis && typeof yAxis === "string"? yAxis : "120%");
  }, [xAxis, yAxis])

  useEffect(() => {
    formatTextMessage();
  }, [text])
  
  const positions = [
    'top-start',
    'top',
    'top-end',
    'left-start',
    'left',
    'left-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end'
  ]

  const tooltipContentStyle = {
    ...fonts.infoText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  };

  if(!loadingTextMessage)
  return(
    <Tooltip
      id={`tooltip-${text}`}
      title={
        <div style={tooltipContentStyle}>
          {textMessage?.map((line, index) => (
            <div key={index} style={{ width: '100%', textAlign: 'center' }}>
              {line}
            </div>
          ))}
        </div>
      }
      placement={positions.includes(position) ? position : 'top'}
      classes={{ tooltip: classes.tooltip }}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      onClose={onClose}
      onOpen={onOpen}
      open={propOpen !== undefined ? propOpen : isHovered}
    >
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
      </Tooltip>
  )
}

TooltipComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  xAxis: PropTypes.any,
  yAxis: PropTypes.any,
  position: PropTypes.string,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
};

export default withStyles(styles)(TooltipComponent);