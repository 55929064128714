/* Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/pt-BR";
/* Material UI */
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField"
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from '@material-ui/core/Checkbox';
/* CSS */
import styles from "./modal-jss";
import fontStyles from "styles/fonts-jss";
/* Context */
import { useModal } from 'Context/ModalContext';

import {
  StyledTableRow,
  StyledCellView,
  StyledNoBorderCellView,
  StyledTableRowDual, 
  StyledCellDual,
  StyledCellDualBorder,
  StyledTableRowLightBorder,
  StyledTableRowNoBorder,
  StyledTableRowVeryLightBorder,
} from 'containers/Pages/Table/Table-jss';

const InternalConsol = (props) => {
    const {classes, data, graphSelecOp, targetsSpecific, getNetworkTarget, initialMonitoringInternal, periodInternal, handlePeriod, checkBoxGeneral,periodAllEspecific, handlePeriodAllEspecific, handleInitialMonitoringInternal, initialMonitoringDate, addNetworkTarget, targetValue, networkTargetsGeneral, setNetworkTargetsGeneral, initDate, finalDate, multipleChecksInit, graphicsSpecific, manipulateTargetsSpecific, manipulateGraphicsSpecific, multiplePeriods, responsible, setResponsible, graphicOptions, addSpecificCheck, specificChecks, handleInitialDateSpecific, handleFinalDateSpecific, getInitialDateSpecific, getFinalDateSpecific} = props;
    const fonts = fontStyles();
    const theme = useTheme();
    const [textFunctionSelect, setTextFunctionSelect] = useState("Remover todos");
    const [functionSelect, setFunctionSelect] = useState([]);
    const [allGraphicOptions, setAllGraphicOptions] = useState([]);
    
    const changeAllEspecific = (especName) => {
        let comp = String(graphicsSpecific.filter((option) => option.includes(targetValue))[0]).split("#")[1];

        let temp = ""
        graphicOptions.map((element, index)=> {
          if(element!="Selecionar todos"){
            temp +=String(element)
            if(index<graphicOptions.length-1){
              temp+=","
            }
          }
        })

        if(comp == temp){
          manipulateTargetsSpecific(especName+"#")
        }else{
          manipulateTargetsSpecific(especName+"#"+temp)
        }
    }

    useEffect(() => {
      let allOp = graphicOptions;
      if(!allOp.includes("Selecionar todos")){
        allOp.unshift("Selecionar todos");
      }
      setAllGraphicOptions(allOp);

    }, []);

    if(data!=undefined && data.length > 0 ){
      return(
        <div>
          {multipleChecksInit.find((filteredButton) => filteredButton === targetValue)===undefined && !multipleChecksInit.includes("AllCheckBoxesInit"+"#"+targetValue)? 
                <>
                  <div style={{marginTop:"20px", marginLeft:"-30px"}}>{targetValue}</div>
                  <StyledTableRowNoBorder>   
                    <StyledNoBorderCellView>
                      <div style={{display:"fex", flexDirection:"column",  alignItems: "center"}}> 
                        <Autocomplete
                          id={"graphics_"+targetValue}
                          multiple={true}
                          disableClearable
                          limitTags={5}
                          value={graphSelecOp?.filter((value) => {return value!="Selecionar todos"})}
                          onChange={(event, newValue) => {
                            if(newValue.includes("Selecionar todos")){
                              changeAllEspecific(targetValue);
                            }else{
                              manipulateTargetsSpecific(targetValue+"#"+newValue);
                            }
                          }}
                          className={
                              classes.selectFieldLargeLightiningIndex
                          }

                          size="small"
                          options={allGraphicOptions}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Gráficos"
                              id={"selectGraphicSpecific#"+targetValue}
                              variant="outlined"
                              className={classes.textFieldNormal}
                              style={{ marginBottom: '0px'}}
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              onKeyDown={(event) => {
                                  if (
                                      event.key ===
                                      'Backspace'
                                  ) {
                                      event.stopPropagation();
                                  }
                              }}
                            />
                          )}
                        />
                        <div style={{padding:"2px 10px 2px 10px"}} className={classes.outlineButton3} onClick={(e) => changeAllEspecific(targetValue)}>
                          <span span>Selecionar/Remover todos</span>
                        </div>
                      </div>
                    </StyledNoBorderCellView>
                    {!initialMonitoringDate && !multiplePeriods&&
                        <StyledNoBorderCellView>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={periodInternal[targetValue]}
                                onChange={()=>handlePeriod(targetValue)}
                                value={"samePeriod_"+targetValue}
                                color="primary"
                              /> )}
                              label="Gerar gráficos para o mesmo período"
                              style={fonts.formsField}
                              className={classes.checkboxModal}
                          />
                        </StyledNoBorderCellView>
                    }
                    {!initialMonitoringDate && !multiplePeriods&&
                        <StyledNoBorderCellView>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={initialMonitoringInternal[targetValue]}
                                onChange={()=>handleInitialMonitoringInternal(targetValue)}
                                value={"initialMonitoring_"+targetValue}
                                color="primary"
                              /> )}
                              label="Gerar desde o início do monitoramento"
                              style={fonts.formsField}
                              className={classes.checkboxModal}
                          />
                        </StyledNoBorderCellView>
                    }
                    {networkTargetsGeneral=="Por alvo"?
                      <StyledNoBorderCellView>
                        <Autocomplete
                            id={"rede_specific_"+targetValue}
                            value={getNetworkTarget(targetValue)}
                            disableClearable
                            onChange={(_, newValue) => addNetworkTarget(targetValue+"#"+newValue)}

                            size="small"
                            options={['Rede satélite', 'Rede solo', 'Todos']}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Selecionar Rede"
                                margin="normal"
                                style={{minWidth:"200px"}}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                        />
                      </StyledNoBorderCellView>
                      : ""
                    }
                  </StyledTableRowNoBorder>
                </>
              :
                <div style={{marginTop:"20px", marginLeft:"30px"}}>
                  <StyledTableRowNoBorder>   
                    <Autocomplete
                      id={"graphics_"+targetValue+"_Specific"}
                      multiple={true}
                      disableClearable
                      limitTags={5}
                      value={graphicsSpecific.filter((option) => option.includes(targetValue).split("#")[0])}
                      onChange={(event, newValue) => {manipulateTargetsSpecific(targetValue+"#"+newValue)}}
                      className={
                          classes.selectTargetsBox
                      }
                      style={fonts.formsFieldHeight1}
                      size="small"
                      options={graphicOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gráficos"
                          id={"selectGraphicSpecific#"+targetValue}
                          variant="outlined"
                          className={classes.textFieldNormal}
                          style={{ marginBottom: '0px' }}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onKeyDown={(event) => {
                              if (
                                  event.key ===
                                  'Backspace'
                              ) {
                                  event.stopPropagation();
                              }
                          }}
                        />
                      )}
                    />
                  </StyledTableRowNoBorder>
                </div>
              }
          {multipleChecksInit.find((filteredButton) => filteredButton === targetValue)===undefined && !multipleChecksInit.includes("AllCheckBoxesInit"+"#"+targetValue) && graphicsSpecific.map((option, genKey) => {                
            if(option.includes(targetValue)){

              let parts = option.split("#");
              if(parts[1].includes(",")){
                let subParts = parts[1].split(",");
                return(
                  subParts.map((actVal, index) => {
                    return(
                      <div key={index} style={{marginLeft:"60px", marginTop:"20px"}}>
                        {!multipleChecksInit.includes("AllCheckBoxesInit"+"#"+targetValue)&&
                          <div style={{marginTop:"20px", marginLeft:"30px"}}>{actVal}</div>
                        }
                        <StyledTableRowVeryLightBorder>
                            {!specificChecks.includes(targetValue+"#"+actVal) && !initialMonitoringDate && !initialMonitoringInternal[targetValue]?
                              !periodInternal[targetValue] &&!multiplePeriods &&
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                  <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    style={{marginLeft:"30px", marginBottom:"15px"}}
                                    animateYearScrolling={false}
                                    value={getInitialDateSpecific(targetValue+"#"+actVal)}
                                    label="Data Inicial"
                                    onChange={(date) => handleInitialDateSpecific(targetValue+"#"+actVal+"#"+date)}
                                    className={classes.dateField}
                                    inputProps={{style: fonts.formsField}}
                                    InputLabelProps={{style: fonts.formsLabel}} 
                                  />
                                </MuiPickersUtilsProvider>
                            : ""}
                            {!specificChecks.includes(targetValue+"#"+actVal) && !initialMonitoringDate && !initialMonitoringInternal[targetValue]?
                              !periodInternal[targetValue] &&!multiplePeriods &&
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                                  <KeyboardDatePicker
                                    format="dd/MM/yyyy"
                                    style={{marginLeft:"20px", marginBottom:"15px"}}
                                    animateYearScrolling={false}
                                    value={getFinalDateSpecific(targetValue+"#"+actVal)}
                                    label="Data Final"
                                    onChange={(date) => handleFinalDateSpecific(targetValue+"#"+actVal+"#"+date)}
                                    className={classes.dateField}
                                    inputProps={{style: fonts.formsField}}
                                    InputLabelProps={{style: fonts.formsLabel}} 
                                  />
                                </MuiPickersUtilsProvider>
                            : ""}
                            {!initialMonitoringDate&& !initialMonitoringInternal[targetValue] &&
                              !periodInternal[targetValue] &&!multiplePeriods &&
                                <FormControlLabel
                                  control={(
                                    <Checkbox
                                      checked={specificChecks.includes(targetValue+"#"+actVal)}
                                      onChange={() => {addSpecificCheck(targetValue+"#"+actVal)}}
                                      value={"GenerateInit"}
                                      color="primary"
                                    /> )}
                                    label="Gerar desde o início do monitoramento"
                                    style={fonts.formsField}
                                    className={classes.checkboxModalDown2}
                                />

                            }
                        </StyledTableRowVeryLightBorder>
                      </div>
                    )
                  })
                )
              }else{
                return(      
                  <div key={genKey} style={{marginLeft:"60px"}}>
                    {!multipleChecksInit.includes("AllCheckBoxesInit"+"#"+targetValue)&&
                      <div style={{marginTop:"20px", marginLeft:"30px"}}>{option.split("#")[1]}</div>
                    }
                    <StyledTableRowVeryLightBorder>
                      {!specificChecks.includes(targetValue+"#"+option.split("#")[1]) && !initialMonitoringDate && !initialMonitoringInternal[targetValue]?
                        !periodInternal[targetValue] &&!multiplePeriods &&
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              style={{marginLeft:"30px", marginBottom:"15px"}}
                              animateYearScrolling={false}
                              value={getInitialDateSpecific(targetValue+"#"+option.split("#")[1])}
                              label="Data Inicial"
                              onChange={(date) => handleInitialDateSpecific(targetValue+"#"+option.split("#")[1]+"#"+date)}
                              className={classes.dateField}
                              inputProps={{style: fonts.formsField}}
                              InputLabelProps={{style: fonts.formsLabel}} 
                            />
                          </MuiPickersUtilsProvider>

                      : ""}
                      {!specificChecks.includes(targetValue+"#"+option.split("#")[1]) && !initialMonitoringDate && !initialMonitoringInternal[targetValue]?
                        !periodInternal[targetValue] &&!multiplePeriods &&
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              style={{marginLeft:"20px", marginBottom:"15px"}}
                              animateYearScrolling={false}
                              value={getFinalDateSpecific(targetValue+"#"+option.split("#")[1])}
                              label="Data Final"
                              onChange={(date) => handleFinalDateSpecific(targetValue+"#"+option.split("#")[1]+"#"+date)}
                              className={classes.dateField}
                              inputProps={{style: fonts.formsField}}
                              InputLabelProps={{style: fonts.formsLabel}} 
                            />
                          </MuiPickersUtilsProvider>
                      : ""}
                      {!initialMonitoringDate&& !initialMonitoringInternal[targetValue]&&
                        !periodInternal[targetValue] &&!multiplePeriods &&
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={specificChecks.includes(targetValue+"#"+option.split("#")[1])}
                                onChange={() => {addSpecificCheck(targetValue+"#"+option.split("#")[1])}}
                                value={"GenerateInit"}
                                color="primary"
                              /> )}
                              label="Gerar desde o início do monitoramento"
                              style={fonts.formsField}
                              className={classes.checkboxModalDown2}
                          />
                      }
                    </StyledTableRowVeryLightBorder>
                  </div>
                )
              }
            }
          })}
        </div>
      )
    }
};

InternalConsol.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.any.isRequired,
    targetValue: PropTypes.any.isRequired,
    initialMonitoringInternal: PropTypes.array.isRequired,
    handleInitialMonitoringInternal: PropTypes.func.isRequired,
    manipulateTargetsSpecific: PropTypes.func.isRequired,
    targetsSpecific: PropTypes.array.isRequired,
    checkBoxGeneral: PropTypes.bool,
    periodInternal: PropTypes.array.isRequired,
    handlePeriod: PropTypes.func.isRequired,
    periodAllEspecific: PropTypes.array.isRequired,
    handlePeriodAllEspecific: PropTypes.func.isRequired,
    multipleChecksInit: PropTypes.array.isRequired,
    initialMonitoringDate: PropTypes.bool.isRequired,
    graphicsSpecific: PropTypes.array.isRequired,
    manipulateGraphicsSpecific: PropTypes.func.isRequired,
    responsible: PropTypes.array,
    graphSelecOp: PropTypes.any,
    setResponsible: PropTypes.func.isRequired,
    graphicOptions: PropTypes.array.isRequired,
    addSpecificCheck: PropTypes.func.isRequired,
    initDate: PropTypes.string.isRequired,
    finalDate: PropTypes.string.isRequired,
    specificChecks: PropTypes.array.isRequired,
    handleInitialDateSpecific: PropTypes.func.isRequired,
    handleFinalDateSpecific: PropTypes.func.isRequired,
    getInitialDateSpecific: PropTypes.func.isRequired,
    getFinalDateSpecific: PropTypes.func.isRequired,
    networkTargetsGeneral: PropTypes.string,
    setNetworkTargetsGeneral: PropTypes.func,
    allTargets: PropTypes.array.isRequired,
    getNetworkTarget: PropTypes.func.isRequired,
    addNetworkTarget: PropTypes.func.isRequired,
    optionsNetwork: PropTypes.array.isRequired,
    multiplePeriods: PropTypes.array.isRequired,
    checkAllPeriodsBoxesInit: PropTypes.func.isRequired,
    checkAllBoxesInit: PropTypes.func.isRequired,

  };
  
  export default withStyles(styles)(InternalConsol);